export const properties = {
  url: "http://www.rutacritica.cl:8085/",
  login: "sigmaserv/login",
  validausuario: "sigmaserv/validausuario",
  companias: "sigmaserv/companias",
  ramos: "sigmaserv/ramos",
  aniomes: "sigmaserv/aniomes",
  clasinds: "sigmaserv/indicadores",
  clasificadores: "sigmaserv/clasificadores",
  ratioxcia: "sigmaserv/ratioxcia",
  ratioxindicador: "sigmaserv/ratioxindicador",
  ratioxclasificador: "sigmaserv/ratioxclasificador",
  ratioxmes: "sigmaserv/ratioxaniomes",
  ratioxramo: "sigmaserv/ratioxramo",
  alertaxclasificador: "sigmaserv/alertaxclasificador",
  alertaxcia: "sigmaserv/alertaxcia",
  alertaxaniomes: "sigmaserv/alertaxaniomes",
  alertaxramo: "sigmaserv/alertaxramo",
  empresainsertalote: "sigmaserv/empresaLote",
  empresainserta: "sigmaserv/empresa",
  empresamodifica: "sigmaserv/empresa",
  empresaelimina: "sigmaserv/empresa",
  empresaeliminasf: "sigmaserv/empresaSF",
  empresaexiste: "sigmaserv/empresaExiste",
  empresapk: "sigmaserv/empresaPK",
  empresanopk: "sigmaserv/empresaNOPK",
  empresasf: "sigmaserv/empresaSF",
  empresaenpermisoempresa: "sigmaserv/empresaenpermisoempresa",
  empresaenpermisoempresacombo: "sigmaserv/empresaenpermisoempresacombo",
  empresanoenpermisoempresa: "sigmaserv/empresanoenpermisoempresa",
  presentacioninserta: "sigmaserv/presentacion",
  presentacionmodifica: "sigmaserv/presentacion",
  presentacionelimina: "sigmaserv/presentacion",
  presentacionexiste: "sigmaserv/presentacionExiste",
  presentacionexistepk: "sigmaserv/presentacionExistePK",
  presentacionpk: "sigmaserv/presentacionPK",
  presentacionnopk: "sigmaserv/presentacionNOPK",
  presentaciondetalleinserta: "sigmaserv/presentaciondetalle",
  presentaciondetallemodifica: "sigmaserv/presentaciondetalle",
  presentaciondetalleelimina: "sigmaserv/presentaciondetalle",
  presentaciondetalleexiste: "sigmaserv/presentaciondetalleExiste",
  presentaciondetallepk: "sigmaserv/presentaciondetallePK",
  presentacionnodetallenopk: "sigmaserv/presentaciondetalleNOPK",
  perfilinserta: "sigmaserv/perfil",
  perfilinsertalote: "sigmaserv/perfilLote",
  perfilmodifica: "sigmaserv/perfil",
  perfilelimina: "sigmaserv/perfil",
  perfileliminasf: "sigmaserv/perfilSF",
  perfilexiste: "sigmaserv/perfilExiste",
  perfilpk: "sigmaserv/perfilPK",
  perfilnopk: "sigmaserv/perfilNOPK",
  perfilsf: "sigmaserv/perfilSF",
  permisocompaniainserta: "sigmaserv/permisocompania",
  permisocompaniaelimina: "sigmaserv/permisocompania",
  permisocompaniaexiste: "sigmaserv/permisocompaniaExiste",
  permisocompaniapk: "sigmaserv/permisocompaniaPK",
  permisoramoinserta: "sigmaserv/permisoramo",
  permisoramoelimina: "sigmaserv/permisoramo",
  permisoramoinsertalote: "sigmaserv/permisoramoLote",
  permisoramoeliminalote: "sigmaserv/permisoramoLote",
  permisoramoexiste: "sigmaserv/permisoramoExiste",
  permisoramopk: "sigmaserv/permisoramoPK",
  ramoenpermisoramo: "sigmaserv/ramoenpermisoramo",
  ramoenpermisoramocombo: "sigmaserv/ramoenpermisoramocombo",
  ramonoenpermisoramo: "sigmaserv/ramonoenpermisoramo",
  usuarioinserta: "sigmaserv/usuario",
  usuariomodifica: "sigmaserv/usuario",
  usuarioelimina: "sigmaserv/usuario",
  usuarioexiste: "sigmaserv/usuarioExiste",
  usuariopk: "sigmaserv/usuarioPK",
  usuarionopk: "sigmaserv/usuarioNOPK",
  usuarioperfilinserta: "sigmaserv/usuarioperfil",
  usuarioperfilmodifica: "sigmaserv/usuarioperfil",
  usuarioperfilelimina: "sigmaserv/usuarioperfil",
  usuarioperfilexiste: "sigmaserv/usuarioperfilExiste",
  usuarioperfilpk: "sigmaserv/usuarioperfilPK",
  usuarioperfilnopk: "sigmaserv/usuarioperfilNOPK",
  permisoempresaelimina: "sigmaserv/permisoempresa",
  permisoempresaeliminalote: "sigmaserv/permisoempresaLote",
  permisoempresainsertalote: "sigmaserv/permisoempresaLote",
};
export default properties;
