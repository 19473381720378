import {
  GRABA_PRESENTACION_ACTION,
  OBTIENE_PRESENTACION_ACTION
} from '../Actions/presentacion.Actions';

const estadoInicialPresentacion = {
  grabando: false,
};

const presentacionObj = (state = estadoInicialPresentacion, action) => {
  switch (action.type) {
    case GRABA_PRESENTACION_ACTION:
      return grabaPresentacion(
        JSON.stringify(action.payload.atributo)
          .replace('"', '')
          .replace('"', ''),
        JSON.stringify(action.payload.dato)
          .replace('"', '')
          .replace('"', ''),
        action.payload.presentacion,
      );
    case OBTIENE_PRESENTACION_ACTION:
      return state;
    default:
      return state;
  }
};

const grabaPresentacion = (atributo, dato, vPresentacion) => {
  var vgrabando = JSON.stringify(vPresentacion.grabando)
    .replace('"', '')
    .replace('"', '');
  if (atributo === 'grabando') {
    vgrabando = dato;
  }
  let objetoPresentacion = {
    grabando: vgrabando,
  };
  return objetoPresentacion;
};

export default presentacionObj;
