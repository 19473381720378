import React, { Component } from 'react';
import {connect} from 'react-redux';
import {obtieneLogin} from '../../../Actions/index';
import Select from 'react-select';
import { properties } from './../properties';
import { presentacionNOPKRest } from './../Rests';

class Presentacion extends Component {

  constructor(props){
    super(props);
    this.state = {
      codempresa: this.props.login.codempresa,
      opPresentacion: [],
      opcionSeleccionada: 0,
      cuadro: this.props.cuadro,
      dato: this.props.dato,
    }
  }

  getPresentacion() {
    var ctoken='pepito';
    var url = properties.url + properties.presentacionnopk;
    var i = 0;
    var resData = [];
    presentacionNOPKRest(url, ctoken, '-1', this.state.codempresa,'1900-01-01',function(response) {
      //console.log('response NOPK afuera: ' + JSON.stringify(response.data) + ';length' + eval(response.data).length);
      for (i=0;i<eval(response.data).length;i++) {
        //console.log('1=' + JSON.stringify(response.data)[i].codpres + '; 2=' + eval(response.data)[i].nompres);
        resData.push({
          value: eval(response.data)[i].codpres,
          label: eval(response.data)[i].nompres,
        });
      }
      this.setState({ opPresentacion: resData});
    }.bind(this))
  }

  componentDidMount(){
    this.getPresentacion();
  }

  cambio = selectedOption => {
    this.setState({ opcionSeleccionada: selectedOption.value });
    let valores = {
      cuadro: this.state.cuadro,
      dato: this.state.dato,
      valor: selectedOption.value,
      label: selectedOption.label
    };
    this.setState({ valores });
    this.props.callbackFromParent(valores);
  };

  render() {
    const { selectedOption } = this.state;
    return(
      <Select style={{width: '40%'}} options={ this.state.opPresentacion } value={selectedOption} onChange={this.cambio} />
    )
  }
}


function mapStateToProps(state) {
  return {
    login: state.loginObj,
  };
}

export default connect(
   mapStateToProps, {
     obtieneLogin,
})(Presentacion);
