import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarAlertaxAnioMes (
  ams7,
  cia7,
  ramo7,
  nomind7,
  cialabel,
  ramolabel,
  indlabel,
  callback
  ) {
  var objRet = {};
  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var opcionesRadar7 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Alerta por Año Mes"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  }
  var ams='';
  var cams='';
  var arramv = [];
  var arrams = [];
  ams = retornoLista(ams7);
  arramv = retornoArregloValor(ams7);
  arramv = arramv.sort();
  arrams = retornaFechasLiteralDeArreglo(arramv.sort());
  cams = stringSeparadoPorComasDeArreglo(arrams);

  var body_stringify = JSON.stringify({
    codcia: cia7,
    codramo: ramo7,
    aniomeslista: ams,
    codind: nomind7
  });

  var ctoken='pepito';
  console.log("GraficarAlexM body = " + body_stringify);
  var url = properties.url + properties.alertaxaniomes;
  var data = [];
  var dataMat = '';
  var j = 0;
  var datacia = null;
  var largo = 0;
  var i = 0;
  var cnt = 0;
  var puntaje = 0;
  var cinds = '';
  var vdato1="";
  var vdato2="";
  var vdato3="";
  var vdato4="";
  var vgrafico = "";
  var vtipoGrafico = "";
  rest_postQuery(url, ctoken, body_stringify, function(response){
    //console.log('response=' + JSON.stringify(response[0]));
    datacia = response[0];
    largo = eval(datacia).length;
    //console.log('response = ' + JSON.stringify(response[0]) + ' cantidad = ' + largo);
    for(i=0;i<largo;i++) {
      data[i]='0';
    }
    for(cnt=0;cnt<largo;cnt++) {
      puntaje = JSON.stringify(datacia[cnt].puntaje).replace('"','').replace('"','');
      cinds = cinds + ',' + JSON.stringify(datacia[cnt].aniomes);
      data[cnt] = puntaje;
      console.log('puntaje = ' + puntaje);
    }
    console.log('cinds = ' + cinds);
    opcionesRadar7.title.text = opcionesRadar7.title.text +
      ' Compañía: ' +
      cialabel.replaceAll('"','') +
      ' Ramo: ' +
      ramolabel.replaceAll('"','') +
      ' Indicador: ' +
      indlabel.replaceAll('"','');
    //data = obtienePuntajexMes(JSON.stringify(response),ramo7);
    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + cams + "],";
    datosGraf = datosGraf + "  datasets: [{";
    datosGraf = datosGraf + "    label: \"\",";
    datosGraf = datosGraf + "    backgroundColor: \"transparent\",";
    datosGraf = datosGraf + "    borderColor: \"rgba(255,99,132,1)\",";
    datosGraf = datosGraf + "    pointRadius: 5,";
    datosGraf = datosGraf + "    pointBorderColor: \"red\",";
    datosGraf = datosGraf + "    pointBackgrounColor: \"red\",";
    datosGraf = datosGraf + "      data: [" + data +  "]";
    datosGraf = datosGraf + "  }]";
    datosGraf = datosGraf + "}";
    /* INICIO DATA PARA CSV */
    dataMat = "Alerta por año mes;Compañía;" + cialabel.replaceAll('"','') + ";Ramo; " + ramolabel.replaceAll('"','') + "\n";
    dataMat += "Indicador-Periodos;" + cams.replaceAll('"','').replaceAll(",",";") + "\n";
    dataMat += indlabel;
    for(j=0;j<data.length;j++) {
      dataMat = dataMat + ";" + data[j];
    }
    dataMat = dataMat + "\n";
    /* FIN DATA PARA CSV */
    vgrafico = "Alerta por Año y Mes";
    vtipoGrafico = "radar";
    vdato1 = "Compañía: " + cialabel;
    vdato2 = "Ramo: " + ramolabel;
    vdato3 = "Periodo(s): " + cams;
    vdato4 = "Indicador: " + indlabel;
    var myObject = eval('(' + datosGraf + ')');
    objRet = {
      dataChart7: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1:vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesRadar7,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
