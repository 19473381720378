import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import estilo from '../Style/style.css';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField  from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import Select from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import properties from '../Components/Home/properties.js';
import {
  ramoenpermisoramoRest,
  ramonoenpermisoramoRest,
  empresaenpermisoempresaRest,
  empresanoenpermisoempresaRest,
  empresaSFRest,
  permisoempresaInsertaLoteRest,
  permisoempresaEliminaLoteRest,
  permisoramoInsertaLoteRest,
  permisoramoEliminaLoteRest
} from '../Components/Home/Rests';
import {
  retornoArregloRamoJSON,
  retornoArregloEmpresaJSON,
} from '../Components/Home/Funciones';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 400,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

var optEmpresa = [];

export default function PermisosVista(props) {
//  var [left, setLeft] = React.useState(["Arina", "Papa", "Pimentón", "Mote"]);
//  var [right, setRight] = React.useState(["Carne", "Ají", "Palta", "Tomate"]);

  var [left, setLeft] = React.useState([]);
  var [right, setRight] = React.useState([]);

  var [leftE, setLeftE] = React.useState([]);
  var [rightE, setRightE] = React.useState([]);

  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [empresa, setEmpresa] = useState({ value:'' , label: '' });
  const [codempresa, setCodempresa] = useState('');
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({ codpersoneria:'' });
  const [perfil, setPerfil] = useState(props.location.state.perfil);

  /* RAMO EN PERMISO
  useEffect(() => {
    var ctoken='123';
    var vperfil='10';
    var data = [];
    var result = '';
    var vcodempresa='123';
    var url = '';
    if(props.location.state!==undefined) {
      alert(props.location.state.perfil);
      vperfil = props.location.state.perfil;
    } else {
      vperfil='123';
    }
    url = properties.url + properties.ramonoenpermisoramo;
    ramonoenpermisoramoRest(url, ctoken, vcodempresa, vperfil, function(response) {
      result = JSON.stringify(response.result).replaceAll('"','');
      console.log("Result=" + result);
      if (result==='OK') {
        data = retornoArregloRamoJSON(JSON.stringify(response.data));
        console.log("Ramo data=" + data);
        setLeft(data);
      }
    });
  }, []);
  /* FIN RAMO EN PERMISO */

  /* RAMO NO EN PERMISO
  useEffect(() => {
    var ctoken='123';
    var data = [];
    var result = '';

    var url = '';
    url = properties.url + properties.ramonoenpermisoramo;
    ramonoenpermisoramoRest(url, ctoken, vcodempresa, perfil, function(response) {
      result = JSON.stringify(response.result).replaceAll('"','');
      data = JSON.stringify(response.data);
      if (result==='OK') {
        data = retornoArregloRamoJSON(JSON.stringify(response.data));
        console.log("Ramo data=" + data)
        setRight(data);
      }
    });
  }, []);
  /* FIN RAMO NO EN PERMISO */

  /* EMPRESA EN PERMISO
  useEffect(() => {
    //var options = [];
    var ctoken='123';
    var vperfil='10';
    var data = [];
    var result = '';
    var vcodempresa='123';
    var url = '';
    if(props.location.state!==undefined) {
      alert(props.location.state.perfil);
      vperfil = props.location.state.perfil;
    } else {
      vperfil='123';
    }
    url = properties.url + properties.empresanoenpermisoempresa;
    empresanoenpermisoempresaRest(url, ctoken, vcodempresa, vperfil, function(response) {
      console.log("RESPONSE EMPRESA: " + JSON.stringify(response.data));
      result = JSON.stringify(response.result).replaceAll('"','');
      console.log("Result=" + result);
      if (result==='OK') {
        data = retornoArregloEmpresaJSON(JSON.stringify(response.data));
        console.log("Empresa data=" + data)
        setLeftE(data);
      }
    });
  }, []);
  /* FIN EMPRESA EN PERMISO */
  /* EMPRESA NO EN PERMISO
  useEffect(() => {
    var ctoken='123';
    var vperfil='10';
    var data = [];
    var result = '';
    var vcodempresa='123';
    var url = '';
    url = properties.url + properties.empresaenpermisoempresa;
    empresaenpermisoempresaRest(url, ctoken, vcodempresa, vperfil, function(response) {
      console.log("RESPONSE EMPRESA: " + JSON.stringify(response.data));
      result = JSON.stringify(response.result).replaceAll('"','');
      data = JSON.stringify(response.data);
      if (result==='OK') {
        data = retornoArregloEmpresaJSON(JSON.stringify(response.data));
        console.log("Empresa data=" + data)
        setRightE(data);
      }
    });
  }, []);
  /* FIN EMPRESA NO EN PERMISO */

  /* EMPRESA */
  useEffect(() => {
    var token='123';
    var i=0;
    var url = '';
    url = properties.url + properties.empresasf;
    empresaSFRest(url,token,function(response) {
      optEmpresa = [];
      let data = JSON.stringify(response.data);
      console.log("Data empresas " + data);
      for(i=0;i<eval(data).length;i++) {
         let valor = JSON.stringify(response.data[i].codempresa).replace("\"","").replace("\"","");
         let etiqueta = JSON.stringify(response.data[i].nomempresa).replace("\"","").replace("\"","");
         optEmpresa.push({ value: valor, label: etiqueta });
      }
      console.log("('<<<<<<<<En LlenaPersoneria3 >>>>>>>>  " + optEmpresa);
      setEmpresa(optEmpresa);
    });
  }, []);
  /* FIN EMPRESA */
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if(empresaSeleccionada.value) {
      setOpen(false);
    } else {
      alert('Debe seleccionar una empresax');
    }
  };



  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const numberOfChecked = (items) => intersection(checked, items).length;
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionado(s)`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );


  const [checkedE, setCheckedE] = React.useState([]);
  const leftCheckedE = intersection(checkedE, leftE);
  const rightCheckedE = intersection(checkedE, rightE);
  const handleToggleE = (value) => () => {
    const currentIndexE = checkedE.indexOf(value);
    const newCheckedE = [...checkedE];

    if (currentIndexE === -1) {
      newCheckedE.push(value);
    } else {
      newCheckedE.splice(currentIndexE, 1);
    }

    setCheckedE(newCheckedE);
  };
  const numberOfCheckedE = (items) => intersection(checkedE, items).length;

  const handleToggleAllE = (items) => () => {
    if (numberOfCheckedE(items) === items.length) {
      setCheckedE(not(checkedE, items));
    } else {
      setCheckedE(union(checkedE, items));
    }
  };
  const handleCheckedRightE = () => {
    setRightE(rightE.concat(leftCheckedE));
    setLeftE(not(leftE, leftCheckedE));
    setCheckedE(not(checkedE, leftCheckedE));
  };
  const handleCheckedLeftE = () => {
    setLeftE(left.concat(rightCheckedE));
    setRightE(not(rightE, rightCheckedE));
    setCheckedE(not(checkedE, rightCheckedE));
  };

  const handleSelectEmpresaChange = (e) => {
    let vempresa = { value: e.value.replace("\"","").replace("\"",""), label: e.label.replace("\"","").replace("\"","")}
    setCodempresa(e.value.replace("\"","").replace("\"",""));
    console.log("E:VALUE====>" + e.value);
    setEmpresaSeleccionada(vempresa);
    var ctoken='123';
    var data = [];
    var result = '';
    var url = '';
    url = properties.url + properties.ramonoenpermisoramo;
    ramonoenpermisoramoRest(url, ctoken, vempresa.value, perfil, function(response) {
      result = JSON.stringify(response.result).replaceAll('"','');
      console.log("Result=" + result);
      if (result==='OK') {
        data = retornoArregloRamoJSON(JSON.stringify(response.data));
        console.log("Ramo data=" + data);
        setLeft(data);
      }
    });

    url = properties.url + properties.ramoenpermisoramo;
    ramoenpermisoramoRest(url, ctoken, vempresa.value, perfil, function(response) {
      result = JSON.stringify(response.result).replaceAll('"','');
      data = JSON.stringify(response.data);
      if (result==='OK') {
        data = retornoArregloRamoJSON(JSON.stringify(response.data));
        console.log("Ramo data=" + data)
        setRight(data);
      }
    });

    url = properties.url + properties.empresanoenpermisoempresa;
    empresanoenpermisoempresaRest(url, ctoken, vempresa.value, perfil, function(response) {
      console.log("RESPONSE EMPRESA: " + JSON.stringify(response.data));
      result = JSON.stringify(response.result).replaceAll('"','');
      data = JSON.stringify(response.data);
      if (result==='OK') {
        data = retornoArregloEmpresaJSON(JSON.stringify(response.data));
        console.log("Empresa data=" + data)
        setLeftE(data);
      }
    });

    url = properties.url + properties.empresaenpermisoempresa;
    empresaenpermisoempresaRest(url, ctoken, vempresa.value, perfil, function(response) {
      console.log("RESPONSE EMPRESA: " + JSON.stringify(response.data));
      result = JSON.stringify(response.result).replaceAll('"','');
      data = JSON.stringify(response.data);
      if (result==='OK') {
        data = retornoArregloEmpresaJSON(JSON.stringify(response.data));
        console.log("Empresa data=" + data)
        setRightE(data);
      }
    });


  }

  const customListE = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAllE(items)}
            checked={numberOfCheckedE(items) === items.length && items.length !== 0}
            indeterminate={numberOfCheckedE(items) !== items.length && numberOfCheckedE(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfCheckedE(items)}/${items.length} seleccionado(s)`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggleE(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedE.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const grabarLotes = () => {
    //alert('data=' + JSON.stringify(state.data) + ' LEN=' + eval(state.data).length);
    var i = 0;
    var token='123';
    //var n = eval(state.data).length;
    var respuesta = '';
    var respuesta2 = '';
    var vdata = null;
    var url = '';
    var arrRamo=[];
    var arrEmpresa=[];
    var JSONRamo = [];
    var JSONEmpresa = [];
    arrRamo = Array.from(right);
    arrEmpresa = Array.from(rightE);
    if (arrRamo.length > 0 && arrEmpresa.length > 0) {
      for (i=0;i<arrRamo.length;i++) {
        JSONRamo.push({
          codempresa: codempresa,
          perfil: perfil,
          codramo: arrRamo[i].split("-")[0],
        });
      }
      url = properties.url + properties.permisoramoeliminalote;
      permisoramoEliminaLoteRest(url, token, codempresa, perfil, function(response) {
        respuesta = JSON.stringify(response.result).replaceAll('"','');
        console.log("RESULTADO RAMO ELIMINACION POR LOTE: " + respuesta + " result = " +  JSON.stringify(response.result));
        url = properties.url + properties.permisoramoinsertalote;
        var objJsonproducto_sringify = JSON.stringify(JSONRamo);
        permisoramoInsertaLoteRest(url, token, objJsonproducto_sringify, function(response) {
          respuesta2 = JSON.stringify(response.result).replaceAll('"','');
          console.log("RESULTADO RAMO INSERCION POR LOTE: " + respuesta2 + " result = " +  JSON.stringify(response.result));
          if (respuesta2==='OK') {
            alert('Ramo Almacenado');
          } else {
            alert('Intente nuevamente por favor');
          }
        });
      });
      //alert(JSON.stringify(JSONRamo));
      for (i=0;i<arrEmpresa.length;i++) {
        JSONEmpresa.push({
          codempresa: codempresa,
          perfil: perfil,
          codcia: arrEmpresa[i].split("-")[0],
        });
      }
      url = properties.url + properties.permisoempresaeliminalote;
      permisoempresaEliminaLoteRest(url, token, codempresa, perfil, function(response) {
        respuesta = JSON.stringify(response.result).replaceAll('"','');
        console.log("RESULTADO EMPRESA ELIMINACION POR LOTE: " + respuesta + " result = " +  JSON.stringify(response.result));
        url = properties.url + properties.permisoempresainsertalote;
        var objJsonproducto_sringify = JSON.stringify(JSONEmpresa);
        permisoempresaInsertaLoteRest(url, token, objJsonproducto_sringify, function(response) {
          respuesta2 = JSON.stringify(response.result).replaceAll('"','');
          console.log("RESULTADO EMPRESA INSERCION POR LOTE: " + respuesta2 + " result = " +  JSON.stringify(response.result));
          if (respuesta2==='OK') {
            alert('Empresa Almacenada');
          } else {
            alert('Intente nuevamente por favor');
          }
        });
      });
      //alert(JSON.stringify(JSONEmpresa));
    } else {
      alert("Debe seleccionar al menos un ramo y una empresa");
    }
  }

  return (
  <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
    <Grid item xs={3} />
    <Grid item xs={3}>
    {/*
    <TextField
      required
      InputProps={{
            readOnly: true,
          }}
      value={empresaSeleccionada.label}
    />
    */}
    <TextField
      required
      InputProps={{
            readOnly: true,
          }}
      value={perfil}
    />
    </Grid>
    <Grid item xs={3}>
    <Select
      onChange={handleSelectEmpresaChange}
      value={optEmpresa.find(op => {return op.value === codempresa})}
      options={optEmpresa}
    />
    </Grid>
    <Grid item xs={3} />
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList('Ramos a seleccionar', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Ramos seleccionados', right)}</Grid>
    </Grid>

    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customListE('Empresas a seleccionar', leftE)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRightE}
            disabled={leftCheckedE.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeftE}
            disabled={rightCheckedE.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customListE('Empresas seleccionadas', rightE)}</Grid>
    </Grid>
    <Grid item xs={5} />
    <Grid item xs={1}>
      <Button
        size="medium"
        variant="outlined"
        color="primary"
        onClick={grabarLotes}>
        Grabar
      </Button>
    </Grid>
    <Grid item xs={5} />
    {/*
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogContent style = {{ height: 280, width: 300}}>
          <DialogContentText>Seleccione aseguradora</DialogContentText>
          <Select
            onChange={handleSelectEmpresaChange}
            value={optEmpresa.find(op => {return op.value === codempresa})}
            options={optEmpresa}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
    */}
  </Grid>
  );
}
