import React, { Component } from 'react';
import Select from 'react-select';
import { properties } from './../properties';
import { rest_get } from './../Rests';

class Aniomes extends Component {

  constructor(props){
    super(props);
    this.state = {
      opAniomes: [],
      opcionSeleccionada: 0,
      cuadro: this.props.cuadro,
      dato: this.props.dato,
    }
  }

  getAnioMes() {
    var ctoken='pepito';
    var url = properties.url + properties.aniomes;
    var parametros=  '';
    rest_get(url, ctoken, parametros, function(response) {
       this.setState({ opAniomes: response });
    }.bind(this))
  }

  componentDidMount() {
    this.getAnioMes();
  }

  cambio = selectedOption => {
    this.setState({ opcionSeleccionada: selectedOption.value });
    let valores = { cuadro: this.state.cuadro, dato: this.state.dato, valor: selectedOption.value, label: selectedOption.label }
    this.setState({ valores });
    this.props.callbackFromParent(valores);
  };

  render() {
    const { selectedOption } = this.state;
    return(
      <Select options={ this.state.opAniomes } autosize={true} value={selectedOption} onChange={this.cambio} />
    )
  }
}

export default Aniomes;