import React from 'react';
import Table from 'react-responsive-data-table';
import settings from '../config/settings.js';

const Tabla = (props) => {


  const hizoClick  = (row) => {
    props.updateData(row);
  }

  return(
    <Table style={{
        opacity: settings.tabla_opacity,
        backgroundColor: settings.tabla_backgroundColor,
        color: settings.tabla_color,
        textAlign: settings.tabla_textAlign
      }}
      tableStyle="table table-hover table-striped table-bordered table-borderless table-responsive"
      pages={true}
      pagination={true}
      onRowClick={(row) => {hizoClick(row)}} // if You Want Table Row Data OnClick then assign this {row => console.log(row)}
      page={true}
      errormsg="Error. . ."
      loadingmsg="Cargando. . ."
      isLoading={false}
      sort={true}
      title= {props.titulo}
      search={true}
      size={10}
      data= {{
        head: props.datosheader,
        data: props.datostabla
      }}
    />
  );
}

export default Tabla;
