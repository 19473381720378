export const GRABA_PRESENTACION_ACTION = 'GRABA_PRESENTACION_ACTION';
export const OBTIENE_PRESENTACION_ACTION = 'OBTIENE_PRESENTACION_ACTION';

export const grabaPresentacion = (vAtributo, vDato, vPresentacion) => {
  return {
    type: GRABA_PRESENTACION_ACTION,
    payload: {
      atributo: vAtributo,
      dato: vDato,
      login: vPresentacion,
    },
  };
};

export const obtienePresentacion = () => {
  return {
    type: OBTIENE_PRESENTACION_ACTION,
  };
};
