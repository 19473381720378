export function retornoLista(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var i = 0;
    var lista = '';
    for (i in myObject) {
      if(i==='0') {
        lista = myObject[i]["value"];
      }
      else {
        lista = lista + ',' + myObject[i]["value"];
      }
    }
    return lista;
}

export function retornoListaLabel(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var i = 0;
    var lista = '';
    for (i in myObject) {
      if(i==='0') {
        lista = myObject[i]["label"];
      }
      else {
        lista = lista + '\",\"' + myObject[i]["label"];
      }
    }
    return lista;
}

export function retornoArregloLabel(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var cont = 0;
    var i = 0;
    var arreglo = [];
    for (i in myObject) {
      arreglo[cont] = myObject[i]["label"];
      cont = cont + 1
    }
    return arreglo;
}

export function retornoArregloValor(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var cont = 0;
    var i = 0;
    var arreglo = [];
    console.log('cadenaJson = ' + cadenaJson + ' myObject ' + myObject);
    for (i in myObject) {
      arreglo[cont] = myObject[i]["value"];
      cont = cont + 1
    }
    return arreglo;
}

export function retornoListaRamoJSON(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var i = 0;
    var lista = '';
    for (i in myObject) {
      if(i==='0') {
        lista = myObject[i]["codramo"] + "-" + myObject[i]["nomramo"];
      }
      else {
        lista = lista + ',' + myObject[i]["codramo"] + "-" + myObject[i]["nomramo"];
      }
    }
    return lista;
}

export function retornoArregloRamoJSON(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var i = 0;
    var arreglo = [];
    for (i in myObject) {
        arreglo[i] = myObject[i]["codramo"] + "-" + myObject[i]["nomramo"];
    }
    return arreglo;
}

export function retornoArregloOrdenadoRamoJSON(cadenaJson) {
  var myObject = JSON.parse(cadenaJson);
  var cont = 0;
  var i = 0;
  var arreglo = [];
  myObject.sort(function (a, b) {
    return a.nomramo.localeCompare(b.nomramo);
  });
  //console.log('cadenaJson = ' + cadenaJson + ' myObject ' + JSON.stringify(myObject));
  for (i in myObject) {
    arreglo[cont] = myObject[i]["nomramo"];
    cont = cont + 1
  }
  return arreglo;
}

export function retornoListaEmpresaJSON(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var i = 0;
    var lista = '';
    for (i in myObject) {
      if(i==='0') {
        lista = myObject[i]["codempresa"] + "-" + myObject[i]["nomempresa"];
      }
      else {
        lista = lista + ',' + myObject[i]["codempresa"] + "-" + myObject[i]["nomempresa"];
      }
    }
    return lista;
}

export function retornoArregloEmpresaJSON(cadenaJson) {
    var myObject = eval('(' + cadenaJson + ')');
    var i = 0;
    var arreglo = [];
    for (i in myObject) {
        arreglo[i] = myObject[i]["codempresa"] + "-" + myObject[i]["nomempresa"];
    }
    return arreglo;
}

export function retornoArregloOrdenadoEmpresaJSON(cadenaJson) {
  var myObject = JSON.parse(cadenaJson);
  var cont = 0;
  var i = 0;
  var arreglo = [];
  myObject.sort(function (a, b) {
    return a.nomempresa.localeCompare(b.nomempresa);
  });
  //console.log('cadenaJson = ' + cadenaJson + ' myObject ' + JSON.stringify(myObject));
  for (i in myObject) {
    arreglo[cont] = myObject[i]["nomempresa"];
    cont = cont + 1
  }
  return arreglo;
}

/*
export function ordenaJSONamsEntero(json) {
  json.sort(function(a, b) {
    return parseInt(a.price) - parseInt(b.price);
  });
}

export function ordenaJSONssFloat(json) {
  json.sort(function(a, b) {
    return parseFloat(a.price) - parseFloat(b.price);
  });
}
*/
export function retornoArregloValorOrdenadoPorLabel(cadenaJson) {
  var myObject = JSON.parse(cadenaJson);
  var cont = 0;
  var i = 0;
  var arreglo = [];
  myObject.sort(function (a, b) {
    return a.label.localeCompare(b.label);
  });
  //console.log('cadenaJson = ' + cadenaJson + ' myObject ' + JSON.stringify(myObject));
  for (i in myObject) {
    arreglo[cont] = myObject[i]["value"];
    cont = cont + 1
  }
  return arreglo;
}

export function retornoArregloLabelDeCadenaJSON(cadenaJson) {
  var myObject = JSON.parse(cadenaJson);
  var cont = 0;
  var i = 0;
  var arreglo = [];
  myObject.sort(function (a, b) {
    return a.label.localeCompare(b.label);
  });
  //console.log('cadenaJson = ' + cadenaJson + ' myObject ' + JSON.stringify(myObject));
  for (i in myObject) {
    arreglo[cont] = myObject[i]["label"];
    cont = cont + 1
  }
  return arreglo;
}

export function colores(i) {
  var colores = [];
  colores[0]='#FF6384';
  colores[1]='#36A2EB';
  colores[2]='#006384';
  colores[3]='#FF00EB';
  colores[4]='#00CE56';
  colores[5]='yellow';
  colores[6]='orange';
  colores[7]='rgba(255,99,132,1)';
  colores[8]='#FFCE56';
  colores[9]='#990056';
  return colores[i];
}

export function coloresRadar(i,j) {
  var colores = [];
  console.log('aqui');
  if(i===0) { // 1er gràfico
     colores[0] = 'rgba(255, 255, 153, 0.2)';
     colores[1] = '#FF6384';
     colores[2] = '#006384';
     colores[3] = 'rgba(255, 255, 153, 1)';
  }
  if(i===1) {
    colores[0] = 'rgba(255, 153, 51, 0.2)';
    colores[1] = '#36A2EB';
    colores[2] = '#776377';
    colores[3] = 'rgba(255, 153, 51, 1)';
  }
  if(i===2) {
    colores[0] = 'rgba(255, 0, 0, 0.2)';
    colores[1] = '#FFCE56';
    colores[2] = '#BBA2EB';
    colores[3] = 'rgba(255, 0, 0, 1)';
  }
  if(i===3) {
    colores[0] = 'rgba(255, 55, 53, 0.2)';
    colores[1] = '#006384';
    colores[2] = '#FFCE16';
    colores[3] = 'rgba(255, 55, 53, 1)';
  }
  if(i===4) {
    colores[0] = 'rgba(255, 100, 200, 0.2)';
    colores[1] = '#00A2EB';
    colores[2] = '#FFCE56';
    colores[3] = 'rgba(255, 100, 200, 1)';
  }
  if(i===5) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#00BE91';
    colores[2] = '#33A2BB';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  if(i===6) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#FF6311';
    colores[2] = '#AA6311';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  if(i===7) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#36A212';
    colores[2] = '#CCCE56';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  if(i===8) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#FFCE13';
    colores[2] = '#DDA212';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  if(i===9) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#006314';
    colores[2] = '#EECEFF';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  if(i===10) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#00A215';
    colores[2] = '#006314';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  if(i===11) {
    colores[0] = 'rgba(255, 200, 100, 0.2)';
    colores[1] = '#00CE16';
    colores[2] = '#AAA215';
    colores[3] = 'rgba(255, 200, 100, 1)';

  }
  console.log('color = ' + colores[j])
  return colores[j];
}

export function obtieneIndicesxMes(strJson,am) {
  var myObject = eval('(' + strJson + ')');
  //console.log('ObjConvertido' + JSON.stringify(myObject));
  var consulta1 = myObject.filter(function (c) {
    return c.aniomes === am;
  });
//  console.log('consulta1=' + JSON.stringify(consulta1));
  var i = 0;
  var data=[];
  var cont = 0;
  for (i in consulta1) {
    data[cont] = consulta1[i]["indice"];
    cont = cont + 1;
  }
  return data;
}

export function obtienePuntajexMes(strJson,am) {
  var myObject = eval('(' + strJson + ')');
  //console.log('ObjConvertido' + JSON.stringify(myObject));
  var consulta1 = myObject.filter(function (c) {
    return c.codramo !== am;
  });
  //console.log('consulta1=' + JSON.stringify(consulta1) + ' para aniomes = ' + am);
  var i = 0;
  var data=[];
  var cont = 0;
  for (i in consulta1) {
    data[cont] = consulta1[i]["puntaje"];
    cont = cont + 1;
  }
  //console.log('data=' + data);
  return data;
}


export function obtieneIndicesxCia(strJson,ccia) {
  var myObject = eval('(' + strJson + ')');
  console.log('ObjConvertido' + JSON.stringify(myObject));
  var consulta1 = myObject.filter(function (c) {
    return c.codcia === ccia;
  });
  var i = 0;
  var data=[];
  var cont = 0;
  for (i in consulta1) {
    data[cont] = consulta1[i]["indice"];
    cont = cont + 1;
  }
  console.log('consulta1 de filtro para = ' + ccia + ' datos = ' + JSON.stringify(consulta1) + ' DATA = ' + data);
  return data;
}

export function obtieneIndicesxInd(strJson,codind) {
  var myObject = eval('(' + strJson + ')');
  console.log('ObjConvertido' + JSON.stringify(myObject));
  var consulta1 = myObject.filter(function (c) {
    return c.codind === codind;
  });
  console.log('consulta1=' + JSON.stringify(consulta1) + ' para codind = ' + codind );
  var i = 0;
  var data=[];
  var cont = 0;
  for (i in consulta1) {
    data[cont] = consulta1[i]["indice"];
    cont = cont + 1;
  }
  return data;
}

export function obtieneIndicesxRamo(strJson,codramo) {
  var myObject = eval('(' + strJson + ')');
  console.log('ObjConvertido' + JSON.stringify(myObject));
  var consulta1 = myObject.filter(function (c) {
    return c.codramo === codramo;
  });
  console.log('consulta1=' + JSON.stringify(consulta1) + ' para codramo = ' + codramo);
  var i = 0;
  var data=[];
  var cont = 0;
  for (i in consulta1) {
    data[cont] = consulta1[i]["indice"];
    cont = cont + 1;
  }
  return data;
}

export function obtieneIndicexMesxRamo(strJson,codmes, codramo) {
  var myObject = eval('(' + strJson + ')');
  var i = 0;
  var data='';
  var cnt=0;
  console.log('ObjConvertido' + JSON.stringify(myObject));
  var consulta1 = myObject.filter(function (c) {
    return c.codmes !==201800
  });
  console.log('consulta1=' + JSON.stringify(consulta1) + ' para codramo = ' + codramo);
  data='0';
  for (i in consulta1) {
    if(consulta1[i]["aniomes"]===codmes && consulta1[i]["codramo"]===codramo) {
      data = consulta1[i]["puntaje"];
    }
  }
  console.log('DATA = ' + data);
  return data;
}

export function stringOrdenadoSeparadoPorComas(dato) {
  var datoarr = [];
  var datoarrsort = [];
  var ind = 0;
  var ams = "";
  var pos = 0;
  const noencontro = -1;
  console.log(dato);
  pos = parseInt(dato.toString().indexOf(","));
  if(pos===noencontro) {
    datoarr[0]=dato;
  }
  else {
    datoarr = dato.split(",");
  }
  console.log("datoarr #######" + datoarr[0] + ' datoarr.length = ' + datoarr.length)
  if(datoarr.length>1) {
    datoarrsort = datoarr.sort();
    console.log("datoarrsort #######" + datoarrsort + ' datoarr.length = ' + datoarrsort.length)
    for(ind=0;ind<datoarrsort.length;ind++) {
      if(ind===0) {
        ams = ams + datoarrsort[ind];
      }
      else {
        ams = ams + "," + datoarrsort[ind];
      }
    }
  }
  else {
    ams = ',' + dato;
  }
  return ams;
}
export function stringSeparadoPorComasDeArreglo(datoarrsort) {
  var ind = 0;
  var ams = "";
  //console.log('####### datoarr.length = ' + datoarrsort.length);
  if(datoarrsort.length>1) {
    //console.log("datoarrsort #######" + datoarrsort);
    for(ind=0;ind<datoarrsort.length;ind++) {
      if(ind===0) {
        ams = ams + "\"" + datoarrsort[ind] + "\"";
      }
      else {
        ams = ams + ",\"" + datoarrsort[ind] + "\"";
      }
    }
  }
  else {
    ams = "\"" + datoarrsort[0] + "\"";
  }
  return ams;
}
/*
export function ordenaJSONamsEntero(json) {
  json.sort(function(a, b) {
    return parseInt(a.price) - parseInt(b.price);
  });
}
*/
export function ordenaJSONamsFloat(json) {
  json.sort(function(a, b) {
    return parseFloat(a.price) - parseFloat(b.price);
  });
}

export function retornaFechasLiteralDeArreglo(arregloFechasNro) {
  var i = 0;
  var mes = '';
  var cmes = '';
  var arreglo = [];
  var dato = '';
  var canio = '';
  //console.log('FUNC : ' + arregloFechasNro);
  for(i=0;i<arregloFechasNro.length;i++) {
    dato = arregloFechasNro[i].toString();
    //console.log('DATO = ' + dato);
    canio = dato.toString().substring(0,4);
    //console.log('CANIO  = <<' + canio + '>>');
    mes = dato.toString().trim().substring(4);
    //console.log('MES = <<' + mes + '>>');
    if(mes === '01') {
      cmes = 'Enero';
    } else if(mes === '02') {
      cmes = 'Febrero';
    } else if(mes === '03') {
      cmes = 'Marzo';
    } else if(mes === '04') {
      cmes = 'Abril';
    } else if(mes === '05') {
      cmes = 'Mayo';
    } else if(mes === '06') {
      cmes = 'Junio';
    } else if(mes === '07') {
      cmes = 'Julio';
    } else if(mes === '08') {
      cmes = 'Agosto';
    } else if(mes === '09') {
      cmes = 'Septiembre';
    } else if(mes === '10') {
      cmes = 'Octubre';
    } else if(mes === '11') {
      cmes = 'Noviembre';
    } else if(mes === '12') {
      cmes = 'Diciembre';
    }
    arreglo[i]=cmes + ' ' + canio;
    //console.log('arreglo[] = <<' + arreglo[i] + '>>');
  }
  return arreglo;
}

export function retornoBackgroundColor() {
  var datosGraf = '';
  datosGraf = datosGraf + "          backgroundColor: [";
  datosGraf = datosGraf + "            \"#FF6384\",";
  datosGraf = datosGraf + "            \"#36A2EB\",";
  datosGraf = datosGraf + "            \"#FFCE56\",";
  datosGraf = datosGraf + "            \"#006384\",";
  datosGraf = datosGraf + "            \"#FFA299\",";
  datosGraf = datosGraf + "            \"#00BE91\",";
  datosGraf = datosGraf + "            \"#FF6311\",";
  datosGraf = datosGraf + "            \"#36A212\",";
  datosGraf = datosGraf + "            \"#FFCE13\",";
  datosGraf = datosGraf + "            \"#006314\",";
  datosGraf = datosGraf + "            \"#00A215\",";
  datosGraf = datosGraf + "            \"#00CE16\",";
  datosGraf = datosGraf + "            ],";
  return datosGraf;
}

export function retornoHoverBackgroundColor () {
  var datosGraf = '';
  datosGraf = datosGraf + "            hoverBackgroundColor: [";
  datosGraf = datosGraf + "            \"#006384\",";
  datosGraf = datosGraf + "            \"#776377\",";
  datosGraf = datosGraf + "            \"#BBA2EB\",";
  datosGraf = datosGraf + "            \"#FFCE56\",";
  datosGraf = datosGraf + "            \"#33A2BB\",";
  datosGraf = datosGraf + "            \"#AA6311\",";
  datosGraf = datosGraf + "            \"#CCCE56\",";
  datosGraf = datosGraf + "            \"#DDA212\",";
  datosGraf = datosGraf + "            \"#EECEFF\",";
  datosGraf = datosGraf + "            \"#006314\",";
  datosGraf = datosGraf + "            \"#AAA215\",";
  datosGraf = datosGraf + "            \"#FFCE16\",";
  datosGraf = datosGraf + "            ],";
  return datosGraf;
}

export function retornoBorderColor () {
  var datosGraf = '';
  datosGraf = datosGraf + "            borderColor: [";
  datosGraf = datosGraf + "            \"#36A2EB\",";
  datosGraf = datosGraf + "            \"rgba(255,99,132,1)\",";
  datosGraf = datosGraf + "            \"rgba(255,99,132,1)\",";
  datosGraf = datosGraf + "            \"rgba(255,99,132,1)\",";
  datosGraf = datosGraf + "            \"rgba(255,99,132,1)\",";
  datosGraf = datosGraf + "            \"rgba(255,99,132,1)\",";
  datosGraf = datosGraf + "            \"#36A211\",";
  datosGraf = datosGraf + "            \"rgba(215,11,112,1)\",";
  datosGraf = datosGraf + "            \"rgba(225,12,122,2)\",";
  datosGraf = datosGraf + "            \"rgba(235,13,132,3)\",";
  datosGraf = datosGraf + "            \"rgba(245,14,142,4)\",";
  datosGraf = datosGraf + "            \"rgba(255,15,152,5)\",";
  datosGraf = datosGraf + "            ]";
  return datosGraf;
}

export function permiso(perfil) {
  var permisos = {
    cliente: false,
    licitacion: false,
    adquirir: false,
    aseguradora: false,
    corredora: false,
    producto: false,
    productoaseguradora: false,
    prima: false,
    Cumulodefecto: false,
    Cumulocliente: false,
    girorestriccion: false,
  };
  if(perfil==='ADMINISTRADOR') {
    permisos.cliente = true;
    permisos.licitacion = true;
    permisos.adquirir = true;
    permisos.aseguradora = true;
    permisos.corredora = true;
    permisos.producto = true;
    permisos.productoaseguradora = true;
    permisos.prima = true;
    permisos.Cumulodefecto = true;
    permisos.Cumulocliente = true;
    permisos.girorestriccion = true;
  }
  else if(perfil==='ASEGURADORA') {
    permisos.cliente = true;
    permisos.licitacion = true;
    permisos.adquirir = true;
    permisos.prima = true;
    permisos.Cumulodefecto = true;
    permisos.Cumulocliente = true;
    permisos.girorestriccion = true;
  }
  if(perfil==='CLIENTE') {
    permisos.cliente = true;
    permisos.licitacion = true;
    permisos.adquirir = true;
  }
  if(perfil==='CORREDORA') {
    permisos.cliente = true;
    permisos.licitacion = true;
    permisos.adquirir = true;
  }
  return permisos;
}
