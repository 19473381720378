export function rest_post(url, ctoken, body_stringify, callback) {
    console.log(url);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': ctoken
        },
        body: body_stringify
    })
    .then((response) => response.json())
    .then((responseJson) => {
        //console.log(responseJson[0])
        callback(responseJson[0]);
    })
    .catch((error) => {
        console.error(error);
    });
}

export function rest_postQuery(url, ctoken, body_stringify, callback) {
    //console.log(url);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': ctoken
        },
        body: body_stringify
    })
    .then((response) => response.json())
    .then((responseJson) => {
        //console.log(' EN REST API ' + responseJson[0]);
        callback(responseJson);
    })
    .catch((error) => {
        console.error(error);
        throw error;
    });
}

export function rest_get(url, ctoken, params, callback) {
    var urlparams = url + params;
    console.log(urlparams);
    return fetch(urlparams, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': ctoken
        },
    })
    .then((response) => response.json())
    .then((responseJson) => {
        //console.log(responseJson[0])
        callback(responseJson[0]);
    })
    .catch((error) => {
        console.error(error);
    });
}

export function rest_put(url, ctoken, body_stringify, params, callback) {
    var urlparams = url + params;
    return fetch(urlparams, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': ctoken
        },
        body: body_stringify
    })
    .then((response) => response.json())
    .then((responseJson) => {
        callback(responseJson);
    })
    .catch((error) => {
        console.error(error);
    });
}

export function rest_delete(url, ctoken, params, callback) {
    var urlparams = url + params;
    return fetch(urlparams, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': ctoken
        },
    })
    .then((response) => response.json())
    .then((responseJson) => {
        callback(responseJson);
    })
    .catch((error) => {
        console.error(error);
    });
}



export function getRatioxcia(vcodcialista,vcodramo,vaniomeslista,vcodind, callback) {

    return fetch('http://localhost:8084/segboind/ratioxcia', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': 'pepito'
        },
        body: JSON.stringify({
            codcialista: vcodcialista,
            codramo: vcodramo,
            aniomeslista: vaniomeslista,
            codind: vcodind
        })
    })
    .then((response) => response.json())
        .then((responseJson) => {
            //console.log(responseJson);
            callback(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function getRatioxcia2(vcodcialista,vcodramo,vaniomeslista,vcodind) {
    fetch('http://localhost:8084/segboind/ratioxcia', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': 'pepito'
        },
        body: JSON.stringify({
            codcialista: vcodcialista,
            codramo: vcodramo,
            aniomeslista: vaniomeslista,
            codind: vcodind
        })
    })
    .then(response =>  response.json())
    .then(resData => {
        //this.setState({ opAniomes: resData });
        //console.log(JSON.stringify(resData));
        return JSON.stringify(resData);
    })
}

export function loginRest(url, vusuario, vclave, callback) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': 'pepito'
        },
        body: JSON.stringify({
            usuario: vusuario,
            clave: vclave,
        })
    })
    .then((response) => response.json())
        .then((responseJson) => {
            //console.log(responseJson);
            callback(responseJson[0]);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function validausuarioRest(url, vusuario, vclave, vtoken, callback) {
    var urlparams = url ;
    return fetch(urlparams, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'token': 'pepito'
        },
        body: JSON.stringify({
            usuario: vusuario,
            clave: vclave,
            token: vtoken,
        })
    })
    .then((response) => response.json())
        .then((responseJson) => {
            //console.log(responseJson);
            callback(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function empresaInsertaRest(url, ctoken, vcodempresa, vnomempresa, callback) {
  //console.log(url + ' cod ' + vcodempresa + ' nom ' + vnomempresa );
  var body_stringify = JSON.stringify({
    codempresa: vcodempresa,
    nomempresa: vnomempresa,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function empresaInsertaLoteRest(url, ctoken, body_stringify, callback) {
  //console.log(url + ' cod ' + vcodempresa + ' nom ' + vnomempresa );
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function empresaModificaRest(url, ctoken, vcodempresa, vnomempresa, callback) {
  var urlparam = url + '/' + vcodempresa;
  //console.log(url);
  var body_stringify = JSON.stringify({
    nomempresa: vnomempresa,
  });
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function empresaEliminaRest(url, ctoken, vcodempresa, callback) {
  var urlparam = url + '/' + vcodempresa;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function empresaEliminaLoteRest(url, ctoken, callback) {
  var urlparam = url;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function empresaExisteRest(url, ctoken, vcodempresa, callback) {
  var urlparam = url + '/' + vcodempresa;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function empresaPKRest(url, ctoken, vcodempresa, callback) {
  var urlparam = url + '/' + vcodempresa;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function empresaNOPKRest(url, ctoken, vnomempresa, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    nomempresa: vnomempresa,
  });
  console.log('url=' + url + ' ; body_stringify=' + body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log('response[0] = ' + JSON.stringify(responseJson))
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function empresaSFRest(url, ctoken, callback) {
  var urlparam = url;
  //console.log("URL " + urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson.data);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function empresaenpermisoempresaRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson)
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function empresaenpermisoempresacomboRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
      //console.log(responseJson[0])
      callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function empresanoenpermisoempresaRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson)
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function presentacionInsertaRest(url, ctoken, vnompres, vcodempresa, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    nompres: vnompres,
    codempresa: vcodempresa
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function presentacionModificaRest(url, ctoken, vcodpres, vnompres, vcodempresa, callback) {
  var urlparam = url + '/' + vcodpres;
  //console.log(url);
  var body_stringify = JSON.stringify({
    nompres: vnompres,
    codempresa: vcodempresa
  });
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function presentacionEliminaRest(url, ctoken, vcodpres, callback) {
  var urlparam = url + '/' + vcodpres;
  //console.log(url);
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function presentacionExisteRest(url, ctoken, vnompres, callback) {
  var urlparam = url + '/' + vnompres;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function presentacionPKRest(url, ctoken, vcodpres, callback) {
  var urlparam = url + '/' + vcodpres;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function presentacionNOPKRest(url, ctoken, vnompres, vcodempresa, vfecha, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    nompres: vnompres,
    codempresa: vcodempresa,
    fecha: vfecha
  });
  console.log('url=' + url + ' ; body_stringify=' + body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log('response[0] = ' + JSON.stringify(responseJson))
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function presentaciondetalleInsertaRest(url, ctoken, vcodpres,vnomcuadro,vgrafico,vtipoGrafico,vdato1,vdato2,vdato3,vdato4,vopciones,vimagen,vdatos,vdataChart,vnro, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    codpres: vcodpres,
    nomcuadro: vnomcuadro,
    grafico: vgrafico,
    tipoGrafico: vtipoGrafico,
    dato1: vdato1,
    dato2: vdato2,
    dato3: vdato3,
    dato4: vdato4,
    opciones: vopciones,
    imagen: vimagen,
    datos: vdatos,
    dataChart: vdataChart,
    nro: vnro,
  });
  console.log("Detalle POST body_stringify = " + body_stringify);
  //console.log("Detalle POST IMAGEN = " + vimagen);
  //console.log("Detalle POST OPCIONES = " + vopciones);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function presentaciondetalleModificaRest(url, ctoken, vcodpres,vnomcuadro,vgrafico,vtipoGrafico,vdato1,vdato2,vdato3,vdato4,vopciones,vimagen,vdatos,vdataChart,vnro, callback) {
  var urlparam = url + '/' + vcodpres + '/' + vnomcuadro;
  //console.log(url);
  var body_stringify = JSON.stringify({
    grafico: vgrafico,
    tipoGrafico: vtipoGrafico,
    dato1: vdato1,
    dato2: vdato2,
    dato3: vdato3,
    dato4: vdato4,
    opciones: vopciones,
    imagen: vimagen,
    datos: vdatos,
    dataChart: vdataChart,
    nro: vnro,
  });
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function presentaciondetalleEliminaRest(url, ctoken, vcodpres, vnomcuadro, callback) {
  var urlparam = url + '/' + vcodpres + '/' + vnomcuadro;
  //console.log(url);
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function presentaciondetalleExisteRest(url, ctoken, vcodpres, vnomcuadro, callback) {
  var urlparam = url + '/' + vcodpres + '/' + vnomcuadro;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function presentaciondetallePKRest(url, ctoken, vcodpres, vnomcuadro, callback) {
  var urlparam = url + '/' + vcodpres + '/' + vnomcuadro;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function presentaciondetalleNOPKRest(url, ctoken, vgrafico,vtipoGrafico,vdato1,vdato2,vdato3,vdato4,vopciones,vimagen,vdatos,vdataChart,vnro, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    grafico: vgrafico,
    tipoGrafico: vtipoGrafico,
    dato1: vdato1,
    dato2: vdato2,
    dato3: vdato3,
    dato4: vdato4,
    opciones: vopciones,
    imagen: vimagen,
    datos: vdatos,
    dataChart: vdataChart,
    nro: vnro,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function perfilInsertaRest(url, ctoken, vperfil, vgraficar, vgrabar, vver, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    perfil: vperfil,
    graficar: vgraficar,
    grabar: vgrabar,
    ver: vver,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function perfilInsertaLoteRest(url, ctoken, body_stringify, callback) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}


export function perfilModificaRest(url, ctoken, vperfil, vgraficar, vgrabar, vver, callback) {
  var urlparam = url + '/' + vperfil;
  //console.log(url);
  var body_stringify = JSON.stringify({
    graficar: vgraficar,
    grabar: vgrabar,
    ver: vver,
  });
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function perfilEliminaRest(url, ctoken, vperfil, callback) {
  var urlparam = url + '/' + vperfil;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function perfilEliminaLoteRest(url, ctoken, callback) {
  var urlparam = url;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function perfilExisteRest(url, ctoken, vperfil, callback) {
  var urlparam = url + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function perfilPKRest(url, ctoken, vperfil, callback) {
  var urlparam = url + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function perfilNOPKRest(url, ctoken, vgraficar, vgrabar, vver, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    graficar: vgraficar,
    grabar: vgrabar,
    ver: vver,
  });
  console.log('url=' + url + ' ; body_stringify=' + body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log('response[0] = ' + JSON.stringify(responseJson))
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function perfilSFRest(url, ctoken, callback) {
  var urlparam = url;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function permisocompaniaInsertaRest(url, ctoken, vcodempresa, vperfil, vcodcia, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    codempresa: vcodempresa,
    perfil: vperfil,
    codcia: vcodcia,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisocompaniaEliminaRest(url, ctoken, vcodempresa, vperfil, vcodcia, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil  + '/' + vcodcia;
  //console.log(url);
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisocompaniaExisteRest(url, ctoken, vcodempresa, vperfil, vcodcia, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil + '/' + vcodcia;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function permisocompaniaPKRest(url, ctoken, vcodempresa, vperfil, vcodcia, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil  + '/' + vcodcia;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}


export function permisoramoInsertaRest(url, ctoken, vcodempresa, vperfil, vcodramo, callback) {
  var body_stringify = JSON.stringify({
    codempresa: vcodempresa,
    perfil: vperfil,
    codramo: vcodramo,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisoramoInsertaLoteRest(url, ctoken, body_stringify, callback) {
  console.log(url + ' body_stringify= ' +  body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisoramoEliminaRest(url, ctoken, vcodempresa, vperfil, vcodramo, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil  + '/' + vcodramo;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}
export function permisoramoEliminaLoteRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisoramoExisteRest(url, ctoken, vcodempresa, vperfil, vcodramo, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil + '/' + vcodramo;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function permisoramoPKRest(url, ctoken, vcodempresa, vperfil, vcodramo, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil  + '/' + vcodramo;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function ramoenpermisoramocomboRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
      //console.log(responseJson[0])
      callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function ramoenpermisoramoRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson)
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function ramonoenpermisoramoRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson)
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function usuarioInsertaRest(url, ctoken, vusuario, vclave, vnombre, vcorreo, vfechaacceso, vfecharegistro, vtoken, vperfil, vcodempresa, callback) {
  var body_stringify = JSON.stringify({
    usuario: vusuario,
    clave: vclave,
    nombre: vnombre,
    correo: vcorreo,
    fechaacceso: vfechaacceso,
    fecharegistro: vfecharegistro,
    token: vtoken,
    perfil: vperfil,
    codempresa: vcodempresa,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log(' EN REST API ' + responseJson);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function usuarioModificaRest(url, ctoken, vusuario, vclave, vnombre, vcorreo, vfechaacceso, vfecharegistro, vtoken, vperfil, vcodempresa, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(url);
  var body_stringify = JSON.stringify({
    clave: vclave,
    nombre: vnombre,
    correo: vcorreo,
    fechaacceso: vfechaacceso,
    fecharegistro: vfecharegistro,
    token: vtoken,
    perfil: vperfil,
    codempresa: vcodempresa,
  });
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function usuarioEliminaRest(url, ctoken, vusuario, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(url);
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function usuarioExisteRest(url, ctoken, vusuario, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function usuarioPKRest(url, ctoken, vusuario, callback) {
  var urlparam = url + '/' + vusuario;
  console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function usuarioNOPKRest(url, ctoken, vclave, vnombre, vcorreo, vfechaacceso, vfecharegistro, vtoken, vperfil, vcodempresa, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    clave: vclave,
    nombre: vnombre,
    correo: vcorreo,
    fechaacceso: vfechaacceso,
    fecharegistro: vfecharegistro,
    token: vtoken,
    perfil: vperfil,
    codempresa: vcodempresa,
  });
  console.log('url=' + url + ' ; body_stringify=' + body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log('response[0] = ' + JSON.stringify(responseJson))
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function usuarioperfilInsertaRest(url, ctoken, vusuario, vperfil, vcodempresa, callback) {
  var body_stringify = JSON.stringify({
    usuario: vusuario,
    perfil: vperfil,
    codempresa: vcodempresa,
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function usuarioperfilModificaRest(url, ctoken, vusuario, vperfil, vcodempresa, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(url);
  var body_stringify = JSON.stringify({
    perfil: vperfil,
    codempresa: vcodempresa,
  });
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function usuarioperfilEliminaRest(url, ctoken, vusuario, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(url);
  return fetch(urlparam, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function usuarioperfilExisteRest(url, ctoken, vusuario, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function usuarioperfilPKRest(url, ctoken, vusuario, callback) {
  var urlparam = url + '/' + vusuario;
  //console.log(urlparam);
  return fetch(urlparam, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(responseJson[0])
    callback(responseJson[0]);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function usuarioperfilNOPKRest(url, ctoken, vperfil, vcodempresa, callback) {
  //console.log(url);
  var body_stringify = JSON.stringify({
    perfil: vperfil,
    codempresa: vcodempresa,
  });
  console.log('url=' + url + ' ; body_stringify=' + body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log('response[0] = ' + JSON.stringify(responseJson))
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function permisoempresaInsertaLoteRest(url, ctoken, body_stringify, callback) {
  console.log(url + ' body_stringify ' + body_stringify);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
    body: body_stringify
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisoempresaEliminaRest(url, ctoken, vcodempresa, vperfil, vcodcia, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil  + '/' + vcodcia;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}

export function permisoempresaEliminaLoteRest(url, ctoken, vcodempresa, vperfil, callback) {
  var urlparam = url + '/' + vcodempresa  + '/' + vperfil;
  //console.log(url);
  return fetch(urlparam, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'token': ctoken
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    //console.log(' EN REST API ' + responseJson[0]);
    callback(responseJson);
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
}
