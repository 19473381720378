import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarRatioxClasificador (
  ams2,
  cia2,
  ramo2,
  clasif2,
  cialabel,
  ramolabel,
  clasiflabel,
  callback
  ) {
  var objRet = {};
  var opcionesBar2 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Ratios por Clasificador"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  };
  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var ams='';
  var cams = '';
  ams = retornoLista(ams2);
  var arrams = [];
  var arramv = [];
  var labelEjeX = '';
  var datocomp1 = 0;
  var datocomp2 = 0;
  var data = [];
  var dataMat = '';
  var datacia = [];
  var i = 0;
  var j = 0;
  var datoant = '';
  var datonvo = '';
  var datocon = '';
  arrams = retornoArregloLabel(ams2);
  arramv = retornoArregloValor(ams2);
  arrams = retornaFechasLiteralDeArreglo(arramv.sort());
  arramv = arramv.sort();
  cams = stringSeparadoPorComasDeArreglo(arrams);
  var vdato1="";
  var vdato2="";
  var vdato3="";
  var vdato4="";
  var vgrafico = "";
  var vtipoGrafico = "";
  var body_stringify = JSON.stringify({
    codcia: cia2,
    codramo: ramo2,
    aniomeslista: ams,
    codclas: clasif2
  });
  var ctoken='pepito';
  console.log("graficarRatioxClasificador body = " + body_stringify);
  var url = properties.url + properties.ratioxclasificador;
  rest_postQuery(url, ctoken, body_stringify, function(response) {
    datacia = response[0];
    for(i=0;i<eval(datacia).length;i++) {
      //console.log('[i]=' + JSON.stringify(datacia[i]) + ' codcia ' + JSON.stringify(datacia[i].codind));
      datonvo=JSON.stringify(datacia[i].codind);
      if(datonvo!==datoant) {
        datocon+= "," + JSON.stringify(datacia[i].nomind);
        datoant=datonvo;
      }
    }
    labelEjeX = datocon.substring(1);
    for(i=0;i<arramv.length;i++) {
      data[i] = '';
      for(j=0;j<eval(datacia).length;j++) {
        datocomp1 = parseInt(JSON.stringify(datacia[j].aniomes).replace('"','').replace('"',''));
        datocomp2 = parseInt(arramv[i]);
        //console.log('datacia[j] <<' + datocomp1 + '>>=== arramv[i] <<' + datocomp2 + '>> res=');
        if(datocomp1===datocomp2) {
          console.log('OKOKOKO indice = ' + JSON.stringify(datacia[j].indice).replace('"','').replace('"',''));
          data[i] = data[i] + ',' + JSON.stringify(datacia[j].indice).replace('"','').replace('"','');
        }
      }
      console.log( 'DATA = <<<'+  data[i].substring(1) + '>>>');
    }
    opcionesBar2.title.text = opcionesBar2.title.text +
      ' Compañía: ' +
      cialabel.replaceAll('"','') +
      ' Ramo: ' +
      ramolabel.replaceAll('"','');
    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + labelEjeX + "],";
    datosGraf = datosGraf + "  datasets: [";
    console.log('labelEjeX = ' + datocon.substring(1) + ' arrams = ' + arrams.sort() + ' arramv = ' + arramv.sort());
    //// SACAR DE ESTE AREGLO {"codind":1013,"aniomes":201912,"codramo":9100,"codcia":108,"indice":"24.32","nomind":"B-Retorno por Colocación de reaseguro"}]
    //// E CAMPO nomind, PERO HACIENDO UN GROUP BY DE ESTE CAMPO PORQUE VIENE REPETIDO POR CADA AÑO
    for(i=0;i<arramv.length;i++) {
      datosGraf = datosGraf + "    {";
      datosGraf = datosGraf + "    label: \"" + arrams[i] + "\",";
      //data = obtieneIndicesxMes(JSON.stringify(response),arramv[i]);
      datosGraf = datosGraf + "    data: [";
      datosGraf = datosGraf + "            " + data[i].substring(1);
      datosGraf = datosGraf + "          ],";
      datosGraf = datosGraf + cBackgroundColor;
      datosGraf = datosGraf + cretornoHoverBackgroundColor;
      datosGraf = datosGraf + cBorderColor;
      datosGraf = datosGraf + "    },";
      /* INICIO DATA PARA CSV*/
      if (i===0) {
        dataMat = "Ratio por clasificador;Compañía;" + cialabel.replaceAll('"','') + ";Ramo;" + ramolabel.replaceAll('"','') + "\n";
        dataMat = dataMat + "Periodo;" + labelEjeX.replaceAll('","',';').replaceAll('"','') + "\n";
      }
      dataMat = dataMat + arrams[i];
      dataMat = dataMat + ";" + data[i].substring(1).replaceAll(',',';');
      dataMat = dataMat + "\n";
      /* FIN DATA PARA CSV*/
    }
    datosGraf = datosGraf + "  ]";
    datosGraf = datosGraf + "}";
    vgrafico = "Ratios por Clase de Indicador";
    vtipoGrafico = "barra";
    vdato1 = "Compañia: " + cialabel;
    vdato2 = "Ramo: " + ramolabel;
    vdato3 = "Periodo(s): " + cams;
    vdato4 = "Clasificador: " + clasiflabel;
    //alert(datosGraf);
    //console.log('<<<<<<>>>>>>datosGraf = ' + datosGraf);
    var myObject = eval('(' + datosGraf + ')');
    objRet = {
      dataChart2: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1: vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesBar2,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
