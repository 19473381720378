import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarAlertaxClasificador (
  cia6,
  ramo6,
  am6,
  claind6,
  cialabel,
  ramolabel,
  amlabel,
  clasiflabel,
  callback
  ) {
  var objRet = {};
  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var opcionesRadar6 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Alerta por Clasificador de Indicador"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  };
  var inds2='';
  var cinds='';
  var arrinds = [];
  var arrindv = [];
  var dataMat = '';
  var j = 0;
  var data = [];
  var ctoken='pepito';
  var url = '';
  var cnt = 0;
  var datacia = [];
  var i = 0;
  var largo = 0;
  var puntaje = 0;
  var vdato1="";
  var vdato2="";
  var vdato3="";
  var vdato4="";
  var vgrafico = "";
  var vtipoGrafico = "";
  var body_stringify = JSON.stringify({
    codcia: cia6,
    codramo: ramo6,
    aniomes: am6,
    codclas: claind6
  });
  url = properties.url + properties.alertaxclasificador;
  //console.log('GraficarAlertaxClasificador url = ' + url + ' body = ' + body_stringify);
  rest_postQuery(url, ctoken, body_stringify, function(response){
    //console.log('response=' + JSON.stringify(response[0]) + ' CINDS=' + cinds);
    datacia = response[0];
    largo = eval(datacia).length;
    //console.log('response = ' + JSON.stringify(response[0]) + ' cantidad = ' + largo);
    for(i=0;i<largo;i++) {
      data[i]='0';
    }
    for(cnt=0;cnt<largo;cnt++) {
      puntaje = JSON.stringify(datacia[cnt].puntaje).replace('"','').replace('"','');
      cinds = cinds + ',' + JSON.stringify(datacia[cnt].nomind);
      data[cnt] = puntaje;
      console.log('puntaje = ' + puntaje);
    }
    opcionesRadar6.title.text = opcionesRadar6.title.text +
      ' Compañía: ' +
      cialabel.replaceAll('"','') +
      ' Ramo: ' +
      ramolabel.replaceAll('"','') +
      ' Periodo: ' +
      amlabel.replaceAll('"','');
    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + cinds.substring(1) + "],";
    datosGraf = datosGraf + "  datasets: [{";
    datosGraf = datosGraf + "    label: \"\",";
    datosGraf = datosGraf + "    backgroundColor: \"transparent\",";
    datosGraf = datosGraf + "    borderColor: \"#36A2EB\",";
    datosGraf = datosGraf + "    pointRadius: 5,";
    datosGraf = datosGraf + "    pointBorderColor: \"red\",";
    datosGraf = datosGraf + "    pointBackgrounColor: \"red\",";
    datosGraf = datosGraf + "    data: [" + data +  "]";
    datosGraf = datosGraf + "  }]";
    datosGraf = datosGraf + "}";
      /* INICIO DATA PARA CSV */
    dataMat = "Alerta por clasificador;Compañía;" + cialabel.replaceAll('"','') + ";Ramo; " + ramolabel.replaceAll('"','') + ";Periodo;" + amlabel.replaceAll('"','') + "\n";
    dataMat += "Ramo" + cinds.replaceAll(',',';').replaceAll('"','') + "\n";
    dataMat = dataMat + ramolabel;
    for(j=0;j<data.length;j++) {
      dataMat = dataMat + ";" + data[j];
    }
    dataMat = dataMat + "\n";
    /* FIN DATA PARA CSV */
    var myObject = eval('(' + datosGraf + ')');
    vgrafico = "Alerta por Clase de Indicador";
    vtipoGrafico = "radar";
    vdato1 = "Compañía: " + cialabel;
    vdato2 = "Ramo: " + ramolabel;
    vdato3 = "Periodo: " + amlabel;
    vdato4 = "Clasificador: " + clasiflabel;
    objRet = {
      dataChart6: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1: vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesRadar6,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
