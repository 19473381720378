import {combineReducers} from 'redux';
import loginReducer from './login.Reducer';
import presentacionReducer from './presentacion.Reducer';

const indexReducers = combineReducers({
  loginObj: loginReducer,
  presentacionObj: presentacionReducer,
});

export default indexReducers;
