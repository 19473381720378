import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarAlertaxCompania (
  cias5,
  ramo5,
  am5,
  nomind5,
  ramolabel,
  indlabel,
  amlabel,
  callback
  ) {
  var objRet = {};
  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var opcionesRadar5 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Alerta por Compañía"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  };
  var cias='';
  var ccias='';
  var j = 0;
  var arrciasv = [];
  var arrciasl = [];
  var myObject = null;
  var dataMat = '';
  var vdato1="";
  var vdato2="";
  var vdato3="";
  var vdato4="";
  var vgrafico = "";
  var vtipoGrafico = "";
  cias = retornoLista(cias5);
  arrciasv = retornoArregloValorOrdenadoPorLabel(cias5);
  arrciasl = retornoArregloLabelDeCadenaJSON(cias5);
  ccias = stringSeparadoPorComasDeArreglo(arrciasl);

  var body_stringify = JSON.stringify({
    codcialista: cias,
    codramo: ramo5,
    aniomes: am5,
    codind: nomind5
  });

  var ctoken='pepito';
  //console.log("GraficarAlexCia body = " + body_stringify);
  var url = properties.url + properties.alertaxcia;
  rest_postQuery(url, ctoken, body_stringify, function(response){
    //console.log('response=' + JSON.stringify(response[0]));
    var data = [];
    data = obtienePuntajexMes(JSON.stringify(response[0]),ramo5);
    opcionesRadar5.title.text = opcionesRadar5.title.text +
      ' Ramo: ' +
      ramolabel.replaceAll('"','') +
      ' Periodo: ' +
      amlabel.replaceAll('"','') +
      ' Indicador: ' +
      indlabel.replaceAll('"','');

    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + ccias + "],";
    datosGraf = datosGraf + "  datasets: [{";
    datosGraf = datosGraf + "    label: \"\",";
    datosGraf = datosGraf + "    backgroundColor: \"transparent\",";
    //datosGraf = datosGraf + "    borderColor: \"rgba(255,99,132,1)\",";
    datosGraf = datosGraf + "    borderColor: \"#36A2EB\",";
    datosGraf = datosGraf + "    pointRadius: 5,";
    datosGraf = datosGraf + "    pointBorderColor: \"red\",";
    datosGraf = datosGraf + "    pointBackgrounColor: \"red\",";
    datosGraf = datosGraf + "      data: [" + data +  "]";
    datosGraf = datosGraf + "  }]";
    datosGraf = datosGraf + "}";
    //alert(datosGraf);
    //console.log('<<<<<<>>>>>>datosGraf = ' + datosGraf);
    myObject = eval('(' + datosGraf + ')');
    /* INICIO DATA PARA CSV */
    dataMat = "Alerta por compañía;Ramo;" + ramolabel.replaceAll('"','') + ";Indicador;" + indlabel.replaceAll('"','') + "\n";
    dataMat = dataMat + "Periodo;" + ccias.replaceAll(',',';').replaceAll('"','') + "\n";
    dataMat = dataMat + amlabel;
    for(j=0;j<data.length;j++) {
      dataMat = dataMat + ";" + data[j];
    }
    dataMat = dataMat + "\n";
    /* FIN DATA PARA CSV */
    var myObject = eval('(' + datosGraf + ')');
    vgrafico = "Alerta por Compañía";
    vtipoGrafico = "radar";
    vdato1 = "Compañía(s): " + ccias;
    vdato2 = "Ramo: " + ramolabel;
    vdato3 = "Periodo: " + amlabel;
    vdato4 = "Indicador: " + indlabel;
    objRet = {
      dataChart5: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1:vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesRadar5,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
