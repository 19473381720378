import React, { Component } from 'react';
import {connect} from 'react-redux';
import {obtieneLogin} from '../../../Actions/index';
import Select from 'react-select';
import { properties } from './../properties';
import { empresaenpermisoempresacomboRest } from './../Rests';

class CompaniaMulti extends Component {
  constructor(props){
    super(props);
    this.state = {
      codempresa: this.props.login.codempresa,
      perfil: this.props.login.perfil,
      opCompania: [],
      cuadro: this.props.cuadro,
      dato: this.props.dato,
    }
  }

  getCompanias() {
    var ctoken = 'pepito';
    var url = properties.url + properties.empresaenpermisoempresacombo;
    empresaenpermisoempresacomboRest(url, ctoken, this.state.codempresa, this.state.perfil, function(response) {
       this.setState({ opCompania: response });
    }.bind(this))
  }


  /*
      , function(response) {
      result = JSON.stringify(response.result).replaceAll('"','');
      //console.log("Result COMPANIA EN PERMISO: " + result + ' data.length ' + response.data.length + ' cod[2]' +  JSON.stringify(response.data[2].nomempresa));
      if (result==='OK') {
        for(i=0;i<response.data.length;i++) {
          data.push({
            value: JSON.stringify(response.data[i].codempresa),
            label: JSON.stringify(response.data[i].nomempresa).replaceAll('"',''),
          });
        }
        console.log("EMPRESA data=" + data);
        this.setState({ opCompania: data});
      }
    }.bind(this))
  }
  */

  componentDidMount(){
    this.getCompanias();
  }



  cambio = selectedOptions => {
    let valores = { cuadro: this.state.cuadro, dato: this.state.dato, valor: selectedOptions }
    this.setState({ valores });
    this.props.callbackFromParent(valores);
    //this.state.selectedOptions.map(o => { alert(o[1]) });
  };

  render() {
    const { selectedOption } = this.state;
    return(
      <Select options={ this.state.opCompania } autosize={true} value={selectedOption} onChange={this.cambio} isMulti= {true}  />
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.loginObj,
  };
}

export default connect(
   mapStateToProps, {
     obtieneLogin,
})(CompaniaMulti);
