import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarRatioxRamo (
  ramos4,
  ams4,
  cia4,
  nomind4,
  cialabel,
  indlabel,
  callback
  ) {
  var objRet = {};
  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var opcionesBar4 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Ratios por Ramo"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  };
  var ramos='';
  var cramos='';
  var cams='';
  var ams='';
  var arramv = [];
  var arrams = [];
  var arrramosl = [];
  var arrramosv = [];
  var data = [];
  var dataMat =  '';
  var indice = 0.0;
  var datacia = [];
  var cnt = 0;
  var datocomp1 = 0;
  var datocomp2 = 0;
  var datocomp3 = 0;
  var datocomp4 = 0;
  var i = 0;
  var j = 0;
  var color = '';
  var myObject = null;
  var vgrafico = "";
  var vtipoGrafico = "";
  var vdato1 = "";
  var vdato2 = "";
  var vdato3 = "";
  var vdato4 = "";
  ramos = retornoLista(ramos4);
  cramos = retornoListaLabel(ramos4);
  cams = retornoListaLabel(ams4);
  ams = retornoLista(ams4);
  arramv = retornoArregloValor(ams4);
  arramv = arramv.sort();
  arrams = retornaFechasLiteralDeArreglo(arramv.sort());
  console.log('arramv = ' + arramv + ' length = ' + arramv.length);
  console.log('arrams = ' + arrams + ' length = ' + arrams.length);

  arrramosv = retornoArregloValorOrdenadoPorLabel(ramos4);
  arrramosl = retornoArregloLabelDeCadenaJSON(ramos4);
  cramos =   stringSeparadoPorComasDeArreglo(arrramosl);

  var body_stringify = JSON.stringify({
    codcia: cia4,
    codramolista: ramos,
    aniomeslista: ams,
    codind: nomind4
  });
  var ctoken='pepito';
  var url = properties.url + properties.ratioxramo;
  console.log("Arreglos arrramos = " + cramos + " arrams = " + cams);
  console.log("graficarRatioxAnioMes body = " + body_stringify);
  rest_postQuery(url, ctoken, body_stringify, function(response) {
    datacia = response[0];
  //  console.log('response = ' + JSON.stringify(response[0]));
    for(j=0;j<arrams.length;j++) {
      data[j]='';
    }
    for(cnt=0;cnt<eval(datacia).length;cnt++) {
      indice = JSON.stringify(datacia[cnt].indice).replace('"','').replace('"','');
      console.log('INDICE = ' + indice);
      datocomp2 =  parseInt(JSON.stringify(datacia[cnt].aniomes));
      datocomp4 = parseInt(JSON.stringify(datacia[cnt].codramo));
      for(i=0;i<arramv.length;i++) {
        for(j=0;j<arrramosv.length;j++) {
          datocomp1 = parseInt(arramv[i]);
          datocomp3 = parseInt(arrramosv[j]);
          if(datocomp1===datocomp2 && datocomp3===datocomp4) {
            data[i] = data[i] + ',' + indice;
            console.log('OKOKOKOK indice =' + indice + " data[j]=" + data[i] + "IIIIII = " + j);
          }
        }
      }
    }
    opcionesBar4.title.text = opcionesBar4.title.text +
      ' Compañía: ' +
      cialabel.replaceAll('"','') +
      ' Indicador: ' +
      indlabel.replaceAll('"','');
    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + cramos + "],";
    datosGraf = datosGraf + "  datasets: [";
    for(i=0;i<arrams.length;i++) {
      //data = obtieneIndicesxMes(JSON.stringify(response),arramv[i]);
      if(data.length > 0) {
        color = colores(i);
        datosGraf = datosGraf + "    {";
        datosGraf = datosGraf + "    label: \"" + arrams[i] + "\",";
        datosGraf = datosGraf + "    data: [";
        datosGraf = datosGraf + "            " + data[i].substring(1);
        datosGraf = datosGraf + "          ],";
        datosGraf = datosGraf + cBackgroundColor;
        datosGraf = datosGraf + cretornoHoverBackgroundColor;
        datosGraf = datosGraf + cBorderColor;
        datosGraf = datosGraf + "    },";
        /* INICIO DATA PARA CSV */
        if (i===0) {
          dataMat = "Ratio por ramo;Compañía;" + cialabel.replaceAll('"','') + ";Indicador;" + indlabel.replaceAll('"','') + "\n";
          dataMat += "Periodo-Ramos;" + cramos.replaceAll(",",";") + "\n";
        }
        console.log('DATAMAT1 = ' + dataMat + ' arrams[i]=' + arrams[i]);
        dataMat = dataMat + arrams[i];
        dataMat = dataMat + ";" + data[i].substring(1).replaceAll(',',';');
        dataMat = dataMat + "\n";
        console.log('DATAMAT1 data[i] = ' + dataMat);
        /* FIN DATA PARA CSV */
      }
    }
    datosGraf = datosGraf + "  ]";
    datosGraf = datosGraf + "}";
    var myObject = eval('(' + datosGraf + ')');
    vgrafico = "Ratios por Ramo";
    vtipoGrafico = "barra";
    vdato1 = "Compañía: " + cialabel;
    vdato2 = "Ramos: " + cramos.replaceAll(',',';').replaceAll('"','');
    vdato3 = "Periodo(s): " + cams;
    vdato4 = "Indicador: " + indlabel;
    objRet = {
      dataChart4: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1: vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesBar4,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
