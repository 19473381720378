import React, { Component } from 'react';
import {connect} from 'react-redux';
import {obtieneLogin} from '../../../Actions/index';
import Select from 'react-select';
import { properties } from './../properties';
import { rest_get } from './../Rests';

class Ramo extends Component {

  constructor(props){
    super(props);
    this.state = {
      codempresa: this.props.login.codempresa,
      perfil: this.props.login.perfil,
      opRamo: [],
      opcionSeleccionada: 0,
      cuadro: this.props.cuadro,
      dato: this.props.dato,
    }
  }

  getRamo() {
    var ctoken='pepito';
    var url = properties.url + properties.ramoenpermisoramocombo;
    var parametros = '/' + this.state.codempresa  + '/' + this.state.perfil;
    rest_get(url, ctoken, parametros, function(resData) {
      this.setState({ opRamo: resData});
    }.bind(this))
  }

  componentDidMount(){
    this.getRamo();
  }

  cambio = selectedOption => {
    //console.log("selectedOption.value=" + selectedOption.value + " ; selectedOption.label=" + selectedOption.label);
    this.setState({ opcionSeleccionada: selectedOption.value });
    let valores = { cuadro: this.state.cuadro, dato: this.state.dato, valor: selectedOption.value, label: selectedOption.label }
    this.setState({ valores });
    this.props.callbackFromParent(valores);
  };

  render() {
    const { selectedOption } = this.state;
    return(
      <Select style={{width: '40%'}} options={ this.state.opRamo } value={selectedOption} onChange={this.cambio} />
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.loginObj,
  };
}

export default connect(
   mapStateToProps, {
     obtieneLogin,
})(Ramo);
