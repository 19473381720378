import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarAlertaxRamo (
  ams8,
  ramos8,
  cia8,
  nomind8,
  cialabel,
  ramolabel,
  indlabel,
  callback
  ) {
  var objRet = {};
  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var opcionesRadar8 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Alerta por Ramo"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  };
  var ramos='';
  var cramos='';
  var ams='';
  var arrAmsl = [];
  var arrAmsv = [];
  var arrRamosl = [];
  var arrRamosv = [];
  var data = [];
  var i=0;
  var j=0;
  var dataAux='';
  var am=0;
  var ramo=0;
  var cams='';
  var cnt=0;
  var datacia = null;
  var largo = 0;
  var puntaje = 0;
  var cinds = '';
  var datacomp1 = 0;
  var datacomp2 = 0;
  var dataMat = '';
  var j = 0;
  var vdato1="";
  var vdato2="";
  var vdato3="";
  var vdato4="";
  var vgrafico = "";
  var vtipoGrafico = "";
  cams = retornoListaLabel(ams8);
  cramos = retornoListaLabel(ramos8);
  ams = retornoLista(ams8);
  ramos = retornoLista(ramos8);

  arrAmsv = retornoArregloValor(ams8);
  arrAmsv = arrAmsv.sort();
  arrAmsl = retornaFechasLiteralDeArreglo(arrAmsv.sort());

  arrRamosv = retornoArregloValorOrdenadoPorLabel(ramos8);
  arrRamosl = retornoArregloLabelDeCadenaJSON(ramos8);
  cramos =   stringSeparadoPorComasDeArreglo(arrRamosl);

  var body_stringify = JSON.stringify({
    codcia: cia8,
    codramolista: ramos,
    aniomeslista: ams,
    codind: nomind8
  });
  var ctoken='pepito';
  console.log("GraficarAlexR body = " + body_stringify);
  var url = properties.url + properties.alertaxramo;
  rest_postQuery(url, ctoken, body_stringify, function(response) {
    console.log('response=' + JSON.stringify(response[0]));
    datacia = response[0];
    largo = eval(datacia).length;
    console.log('response = ' + JSON.stringify(response[0]) + ' cantidad = ' + largo);
    for(i=0;i<arrAmsv.length;i++) {
      data[i]='';
    }
    for(cnt=0;cnt<largo;cnt++) {
      datacomp1 = parseInt(JSON.stringify(datacia[cnt].aniomes));
      puntaje = JSON.stringify(datacia[cnt].puntaje).replace('"','').replace('"','');
      for(i=0;i<arrAmsv.length;i++) {
        datacomp2 = parseInt(arrAmsv[i]);
        if(datacomp1===datacomp2) {
          data[i] = data[i] + "," + puntaje;
        }
      }
      cinds = cinds + ',' + JSON.stringify(datacia[cnt].aniomes);
      console.log('puntaje = ' + data);
    }
    console.log('cinds = ' + cinds);
    opcionesRadar8.title.text = opcionesRadar8.title.text +
      ' Compañía: ' +
      cialabel.replaceAll('"','') +
      ' Indicador: ' +
      indlabel.replaceAll('"','');
    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + cramos + "],";
    datosGraf = datosGraf + "  datasets: [";
    for(i=0;i<arrAmsl.length;i++) {
      datosGraf = datosGraf + "     {";
      datosGraf = datosGraf + "      label: \"" + arrAmsl[i] + "\",";
      datosGraf = datosGraf + "      backgroundColor: \"transparent\",";
      datosGraf = datosGraf + "      borderColor: \"" + coloresRadar(i,1) + "\",";
      datosGraf = datosGraf + "      pointBorderColor: \"" + coloresRadar(i,2) + "\",";
      datosGraf = datosGraf + "      pointBackgrounColor: \"" + coloresRadar(i,3) + "\",";
      datosGraf = datosGraf + "      pointRadius: 3,";
      datosGraf = datosGraf + "      data: [" + data[i].substring(1) + "]";
      datosGraf = datosGraf + "     },";
      /* INICIO DATA PARA CSV */
      if (i===0) {
        dataMat = "Alerta por año ramo;Compañía;" + cialabel.replaceAll('"','') + ";Indicador;" + indlabel.replaceAll('"','') + "\n";
        dataMat += "Periodo-Ramos;" + cramos.replaceAll(',',';').replaceAll('"','') + "\n";
      }
      //dataMat = dataMat + indlabel;
      //for(j=0;j<data.length;j++) {
        dataMat = dataMat + arrAmsl[i] + ";" + data[i].substring(1).replaceAll(',',';') + "\n";
      //}
      /* FIN DATA PARA CSV */
    }
    datosGraf = datosGraf + "   ]";
    datosGraf = datosGraf + "}";
    var myObject = eval('(' + datosGraf + ')');
    vgrafico = "Alerta por Ramo";
    vtipoGrafico = "radar";
    vdato1 = "Compañía: " + cialabel;
    vdato2 = "Ramo: " + cramos.replaceAll(',',';').replaceAll('"','');
    vdato3 = "Periodo(s): " + cams;
    vdato4 = "Indicador: " + indlabel;
    objRet = {
      dataChart8: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1:vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesRadar8,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
