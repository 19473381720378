import React, { Component } from 'react'
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import indexReducers from './Reducers/index.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './Components/Shared/Header'
//import HeaderMenu from './Components/Shared/HeaderMenu'
import Footer from './Components/Shared/Footer';
import Home from './Components/Home/index_nvo';
import Login from './Components/Home/Login';
import EmpresaVista from './Vista/EmpresaVista';
import PerfilVista from './Vista/PerfilVista';
import UsuarioVista from './Vista/UsuarioVista';
import PermisosVista from './Vista/PermisosVista';
import MenuRoute from './Vista/MenuRoute';
/*
import PerfilVista from './Vista/PerfilVista';
import PermisoCompaniaVista from './Vista/PermisoCompaniaVista';
import PermisoRamoVista from './Vista/PermisoRamoVista';
import UsuarioVista from './Vista/UsuarioVista';
import UsuarioPerfilVista from './Vista/UsuarioPerfilVista';
*/
const store = createStore(indexReducers);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Header />
            <Switch>
              <Route exact path='/' component={Login} />
              <Route path='/home' component={Home} />
              <Route path='/empresa' component={EmpresaVista} />
              <Route path='/usuario' component={UsuarioVista} />
              <Route path='/perfil' component={PerfilVista} />
              <Route path='/permisos' component={PermisosVista} />
              <Route path='/menu' component={MenuRoute} />
            </Switch>
            <Footer/>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App;
