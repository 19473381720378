import React, { Component, useRef } from 'react';

import {connect} from 'react-redux';
//import {obtieneLogin} from '../../../src/Actions/index';
import {obtieneLogin, obtienePresentacion, grabaPresentacion} from '../../Actions/index';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bar, Line, Radar } from 'react-chartjs-2';
import Blink from 'react-blink-text';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SaveIcon from '@material-ui/icons/Save';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton } from '@material-ui/core';
import TableChartTwoToneIcon from '@material-ui/icons/TableChartTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import saveAs from 'file-saver';
import "chartjs-plugin-datalabels";
import "react-tabs/style/react-tabs.css";
import AnioMes from './Select/Aniomes';
import AnioMesMulti from './Select/AniomesMulti';
import Compania from './Select/Compania';
import CompaniaMulti from './Select/CompaniaMulti';
import Ramo from './Select/Ramo';
import RamoMulti from './Select/RamoMulti';
import Clasind from './Select/Clasind';
import Clasificadores from './Select/Clasificadores';
import Presentacion from './Select/Presentacion';
//import ClasindMulti from './Select/ClasindMulti';
//import ClasnomMulti from './Select/ClasnomMulti';
//import Clasranking from './Select/ClTableasranking';
//import ClasrankingMulti from './Select/ClasrankingMulti';
import { rest_postQuery,
         presentacionInsertaRest,
         presentaciondetalleInsertaRest,
         presentaciondetallePKRest,
       } from './Rests';
import { retornoLista,
         retornoListaLabel,
         retornoArregloLabel,
         retornoArregloValorOrdenadoPorLabel,
         obtieneIndicesxMes,
//         obtieneIndicesxRamo,
         retornoArregloValor,
//         obtieneIndicesxCia,
         obtienePuntajexMes,
//         obtieneIndicexMesxRamo,
         colores,
         coloresRadar,
         stringOrdenadoSeparadoPorComas,
         retornaFechasLiteralDeArreglo,
         stringSeparadoPorComasDeArreglo,
         retornoArregloLabelDeCadenaJSON,
         retornoBackgroundColor,
         retornoHoverBackgroundColor,
         retornoBorderColor,
       }
         from './Funciones';
import { properties } from './properties';
import Tabla from '../Tabla';
import { graficarRatioxCompania } from '../Funciones/RatioxCia';
import { graficarRatioxClasificador } from '../Funciones/RatioxClas';
import { graficarRatioxAnioMes } from '../Funciones/RatioxAnioMes';
import { graficarRatioxRamo } from '../Funciones/RatioxRamo';
import { graficarAlertaxCompania } from '../Funciones/AlertaxCompania';
import { graficarAlertaxAnioMes } from '../Funciones/AlertaxAnioMes';
import { graficarAlertaxClasificador } from '../Funciones/AlertaxClasificador';
import { graficarAlertaxRamo } from '../Funciones/AlertaxRamo';
const cBackgroundColor = retornoBackgroundColor();
const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
const cBorderColor = retornoBorderColor();

var datosalida = [];

var datosPK = [];


class Home extends Component {
  constructor(props){
    super(props);
    this.chartReference = React.createRef();
    datosalida = [];
    datosPK = [];
    var headsalida = { cuadro : "Cuadro", tipoGrafico: "Tipo gráfico", grafico: "Gráfico" };
    var objLogin = null;
    console.log('graficar=' + this.props.login.graficar + ';grabar=' + this.props.login.grabar + ';ver=' + this.props.login.ver);
    //alert('props=' + JSON.stringify(props.obtieneLogin()));
    objLogin = this.props.obtieneLogin();
    //alert('props.login.stringify=' + JSON.stringify(this.props.login));
    //alert('usuario: ' + this.props.login.usuario + ' clave: ' + this.props.login.clave + ' token: ' + this.props.login.token);
    this.state = {
      graficar: this.props.login.graficar,
      grabar: this.props.login.grabar,
      ver: this.props.login.ver,
      dataChart1: null,
      dataChart2: null,
      dataChart3: null,
      dataChart4: null,
      dataChart5: null,
      dataChart6: null,
      dataChart7: null,
      dataChart8: null,
      dataChart9: null,
      dataChart10: null,
      dataChart11: null,
      dataChart12: null,
      dataChart: null,

      ramoSeleccionado: 0,
      ramo1: 0,
      cias1: [],
      ams1: [],
      nomind1: 0,

      ramo2: 0,
      cia2: 0,
      ams2: [],
      clasif2: 0,

      ramo3: 0,
      cias3: [],
      ams3: [],
      nomind3: 0,

      ramos4: [],
      cia4: 0,
      ams4: [],
      nomind4: 0,

      ramo5: 0,
      cia5: [],
      am5: 0,
      nomind5: 0,

      ramo6: 0,
      cia6: 0,
      am6: 0,
      claind6: 0,

      ramo7: 0,
      cias7: [],
      am7: 0,
      nomind7: 0,

      ramos8: [],
      cias8: [],
      ams8: [],
      nomind8: 0,

      ramo9: 0,
      cias9: [],
      ams9: [],
      claran9: 0,

      ramo10: 0,
      cia10: 0,
      ams10: [],
      clarans10: [],

      ramo11: 0,
      cias11: [],
      ams11: [],
      claran11: 0,

      ramos12: [],
      cia12: 0,
      ams12: [],
      claran12: 0,

      inds2: [],
      inds6: [],

      cargando: false,

      arrPresentacion: [],
      datosalida: [],

      ramolabel: '',
      indlabel: '',
      cialabel: '',
      clasiflabel: '',
      amlabel: '',
      pres: '',
      preslabel: '',

      opciones: {},

      nomPresentacion: '',
      grabando: false,
      botonGrabarHabilitado: false,
      botonGrabacion: 'Comenzar grabación',
      tipoGrafico: '',
      grafico: '',
      dato1: '',
      dato2: '',
      dato3: '',
      dato4: '',
      imagen: '',
      data: [],
      datos: [],
      dataMat: '',
      nro: 0,
      nomCuadro: '',

      titulox: 'Cuadros presentación',
      headsalida: headsalida,
      pos: 0,
    }
  }

  cambioTab = (nroTab) => {
    this.setState ({
      botonGrabarHabilitado: false,
      nomCuadro: '',
      ramolabel: '',
      indlabel: '',
      cialabel: '',
      clasiflabel: '',
      amlabel: '',
      nomPressentacion: '',
      tipoGrafico: '',
      grafico: '',
      dato1: '',
      dato2: '',
      dato3: '',
      dato4: '',
      imagen: '',
      opciones: {},
      dataChart1: {},
      dataChart2: {},
      dataChart3: {},
      dataChart4: {},
      dataChart5: {},
      dataChart6: {},
      dataChart7: {},
      dataChart8: {}
    });
  }

  myCallback = (dataFromChild) => {
    this.setState({ ramoSeleccionado: dataFromChild });
    var vcuadro = JSON.stringify(dataFromChild.cuadro).replace("\"","").replace("\"","");
    var dato = JSON.stringify(dataFromChild.dato).replace("\"","").replace("\"","");
    var valor = JSON.stringify(dataFromChild.valor);
    var label = JSON.stringify(dataFromChild.label);
    var objRet1 = {}
    var ctoken='pepito';
    var url = '';
    var i = 0;

    if(vcuadro === '1') {
      if(dato==='cias1') {
        this.setState({ cuadro: vcuadro, cias1: valor });
      }
      else if(dato==='ramo1') {
        this.setState({ ramo1: valor, ramolabel: label });
      }
      else if(dato==='ams1') {
        this.setState({ ams1: valor, amlabel: label });
      }
      else if(dato==='nomind1') {
        this.setState({ nomind1: valor, indlabel: label });
      }
    }
    else if(vcuadro==='2') {
      if(dato==='cia2') {
        this.setState({ cia2: valor, cialabel: label });
      }
      else if(dato==='ramo2') {
        this.setState({ ramo2: valor, ramolabel: label });
      }
      else if(dato==='ams2') {
        this.setState({ ams2: valor, amlabel: label });
      }
      else if(dato==='clasif2') {
        this.setState({ clasif2: valor, clasiflabel: label });
      }
    }
    else if(vcuadro==='3') {
      if(dato==='cias3') {
        this.setState({ cias3: valor });
      }
      else if(dato==='ramo3') {
        this.setState({ ramo3: valor, ramolabel: label });
      }
      else if(dato==='ams3') {
        this.setState({ ams3: valor, amlabel: label });
      }
      else if(dato==='nomind3') {
        this.setState({ nomind3: valor, indlabel: label });
      }
    }
    else if(vcuadro==='4') {
      if(dato==='cia4') {
        this.setState({ cia4: valor, cialabel: label });
      }
      else if(dato==='ramos4') {
        this.setState({ ramos4: valor, ramolabel: label });
      }
      else if(dato==='ams4') {
        this.setState({ ams4: valor, amlabel: label });
      }
      else if(dato==='nomind4') {
        this.setState({ nomind4: valor, indlabel: label });
      }
    }
    else if(vcuadro==='5') {
      if(dato==='cias5') {
        this.setState({ cias5: valor });
      }
      else if(dato==='ramo5') {
        this.setState({ ramo5: valor, ramolabel: label });
      }
      else if(dato==='am5') {
        this.setState({ am5: valor, amlabel: label });
      }
      else if(dato==='nomind5') {
        this.setState({ nomind5: valor, indlabel: label });
      }
    }
    else if(vcuadro==='6') {
      if(dato==='cia6') {
        this.setState({ cia6: valor, cialabel: label });
      }
      else if(dato==='ramo6') {
        this.setState({ ramo6: valor, ramolabel: label });
      }
      else if(dato==='am6') {
        this.setState({ am6: valor, amlabel: label });
      }
      else if(dato==='claind6') {
        this.setState({ claind6: valor, clasiflabel: label });
      }
    }
    else if(vcuadro==='7') {
      if(dato==='cia7') {
        this.setState({ cia7: valor, cialabel: label });
      }
      else if(dato==='ramo7') {
        this.setState({ ramo7: valor, ramolabel: label });
      }
      else if(dato==='ams7') {
        this.setState({ ams7: valor, amlabel: label });
      }
      else if(dato==='nomind7') {
        this.setState({ nomind7: valor, indlabel: label });
      }
    }
    else if(vcuadro==='8') {
      if(dato==='cia8') {
        this.setState({ cia8: valor, cialabel: label });
      }
      else if(dato==='ramos8') {
        this.setState({ ramos8: valor,ramolabel: label });
      }
      else if(dato==='ams8') {
        this.setState({ ams8: valor, amlabel: label });
      }
      else if(dato==='nomind8') {
        this.setState({ nomind8: valor, indlabel: label });
      }
    }
    else if(vcuadro==='9') {
      if(dato==='cias9') {
        this.setState({ cias9: valor });
      }
      else if(dato==='ramo9') {
        this.setState({ ramo9: valor, ramolabel: label });
      }
      else if(dato==='ams9') {
        this.setState({ ams9: valor });
      }
      else if(dato==='claran9') {
        this.setState({ claran9: valor });
      }
    }
    else if(vcuadro==='10') {
      var vArrPresentacion = [];
      var objPresentacion = {};
      var texto = "";
      console.log('aqui 10');
      if(dato==='pres10') {
        this.setState({ pres: valor, preslabel: label });
        url = properties.url + properties.presentaciondetallepk;
        presentaciondetallePKRest(url, ctoken, valor, '-1',function(response) {
          //console.log('LENGTH=======' + eval(response.data).length + '; response afuera: ' + JSON.stringify(response));
          for (i=0;i<eval(response.data).length;i++) {
            texto = "";
            console.log('IIII===' + i +
              '  JSON.grafico  ' +
              JSON.stringify(eval(response.data)[i].grafico).replaceAll('"','') +
              '  JSON.tipoGrafico  ' +
              JSON.stringify(eval(response.data)[i].tipoGrafico).replaceAll('"','') +
              '  JSON.opciones  ' +
              JSON.parse(JSON.stringify(eval(response.data)[i].opciones)) +
              '  JSON.imagen  ' +
              JSON.parse(JSON.stringify(eval(response.data)[i].imagen))
            );
            objPresentacion = {
              codpres: JSON.stringify(eval(response.data)[i].codpres),
              nomCuadro: JSON.stringify(eval(response.data)[i].nomcuadro),
              grafico: JSON.stringify(eval(response.data)[i].grafico).replaceAll('"',''),
              text: JSON.stringify(response.data[i].dato1) + ' '+
                JSON.stringify(response.data[i].dato2) + ' ' +
                JSON.stringify(response.data[i].dato3) + ' ' +
                JSON.stringify(response.data[i].dato4).replaceAll('"',''),
              tipoGrafico: JSON.stringify(eval(response.data)[i].tipoGrafico).replaceAll('"',''),
              opciones: JSON.parse(JSON.stringify(eval(response.data)[i].opciones)),
              imagen: JSON.parse(JSON.stringify(eval(response.data)[i].imagen)),
            };
            //console.log('######EEEEVVAAALLLL' + i + ')' + JSON.stringify(response.data)[i]);
            vArrPresentacion.push(objPresentacion);
            //console.log('vArrPresentacion dataChart= ' + JSON.parse(JSON.stringify(eval(response.data)[i].dataChart.replace("\n",""))));
          }
          this.setState({
            arrPresentacion: vArrPresentacion,
          });
        }.bind(this))
      }
      else if(dato==='ramo10') {
        this.setState({ ramo10: valor });
      }
      else if(dato==='ams10') {
        this.setState({ ams10: valor });
      }
      else if(dato==='clarans10') {
        this.setState({ clarans10: valor });
      }
    }
    else if(vcuadro==='11') {
      if(dato==='cias11') {
        this.setState({ cias11: valor });
      }
      else if(dato==='ramo11') {
        this.setState({ ramo11: valor });
      }
      else if(dato==='ams11') {
        this.setState({ ams11: valor });
      }
      else if(dato==='claran11') {
        this.setState({ claran11: valor });
      }
    }
    else if(vcuadro==='12') {
      if(dato==='cia12') {
        this.setState({ cia12: valor });
      }
      else if(dato==='ramos12') {
        this.setState({ ramos12: valor });
      }
      else if(dato==='ams12') {
        this.setState({ ams12: valor });
      }
      else if(dato==='claran12') {
        this.setState({ claran12: valor });
      }
    }
  }
  graficarRatioxCompania_Click = () => {
    var opcionesBar1 = {};
    var objRet = {};
    this.setState({dataChart1: null, cargando: true});
    (async () => {
      graficarRatioxCompania(this.state.cias1,
        this.state.ams1,
        this.state.ramo1,
        this.state.nomind1,
        this.state.nro,
        this.state.ramolabel,
        this.state.indlabel,
        function(objRet) {
          opcionesBar1 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + objRet.imagen + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart1: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart1 ###****** ' +
          this.state.dataChart1);
      }.bind(this));
    })();
  }

  graficarRatioxClasificador_Click = () => {
    var opcionesBar2 = {};
    var objRet = {};
    this.setState({dataChart2: null, cargando: true});
    (async () => {
      graficarRatioxClasificador(
        this.state.ams2,
        this.state.cia2,
        this.state.ramo2,
        this.state.clasif2,
        this.state.cialabel,
        this.state.ramolabel,
        this.state.clasiflabel,
        function(objRet) {
          opcionesBar2 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart2: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart1 ###****** ' +
          this.state.dataChart2);
      }.bind(this));
    })();
  }

  graficarRatioxAnioMes_Click = () => {
    var opcionesBar3 = {};
    var objRet = {};
    this.setState({dataChart3: null, cargando: true});
    (async () => {
      graficarRatioxAnioMes(
        this.state.cias3,
        this.state.ams3,
        this.state.ramo3,
        this.state.nomind3,
        this.state.ramolabel,
        this.state.indlabel,
        function(objRet) {
          opcionesBar3 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart3: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart1 ###****** ' +
          this.state.dataChart3);
      }.bind(this));
    })();
  }

  graficarRatioxRamo_Click = () => {
    var opcionesBar4 = {};
    var objRet = {};
    this.setState({dataChart4: null, cargando: true});
    (async () => {
      graficarRatioxRamo(
        this.state.ramos4,
        this.state.ams4,
        this.state.cia4,
        this.state.nomind4,
        this.state.cialabel,
        this.state.indlabel,
        function(objRet) {
          opcionesBar4 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart4: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart1 ###****** ' +
          this.state.dataChart4);
      }.bind(this));
    })();
  }

  graficarAlertaxCompania_Click= () => {
    var opcionesRadar5 = {};
    var objRet = {};
    this.setState({dataChart5: null, cargando: true});
    (async () => {
      graficarAlertaxCompania(
        this.state.cias5,
        this.state.ramo5,
        this.state.am5,
        this.state.nomind5,
        this.state.ramolabel,
        this.state.indlabel,
        this.state.amlabel,
        function(objRet) {
          opcionesRadar5 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart5: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart1 ###****** ' +
          this.state.dataChart4);
      }.bind(this));
    })();
  }

  graficarAlertaxClasificador_Click = () => {
    var opcionesRadar6 = {};
    var objRet = {};
    this.setState({dataChart6: null, cargando: true});
    (async () => {
      graficarAlertaxClasificador(
        this.state.cia6,
        this.state.ramo6,
        this.state.am6,
        this.state.claind6,
        this.state.cialabel,
        this.state.ramolabel,
        this.state.amlabel,
        this.state.clasiflabel,
        function(objRet) {
          opcionesRadar6 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart6: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart1 ###****** ' +
          this.state.dataChart6);
      }.bind(this));
    })();
  }

  graficarAlertaxAnioMes_Click = () => {
    var opcionesRadar7 = {};
    var objRet = {};
    this.setState({dataChart7: null, cargando: true});
    (async () => {
      graficarAlertaxAnioMes(
        this.state.ams7,
        this.state.cia7,
        this.state.ramo7,
        this.state.nomind7,
        this.state.cialabel,
        this.state.ramolabel,
        this.state.indlabel,
        function(objRet) {
          opcionesRadar7 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart7: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart7 ###****** ' +
          this.state.dataChart7);
      }.bind(this));
    })();
  }

  graficarAlertaxRamo_Click = () => {
    var opcionesRadar8 = {};
    var objRet = {};
    this.setState({dataChart8: null, cargando: true});
    (async () => {
      graficarAlertaxRamo(
        this.state.ams8,
        this.state.ramos8,
        this.state.cia8,
        this.state.nomind8,
        this.state.cialabel,
        this.state.ramolabel,
        this.state.indlabel,
        function(objRet) {
          opcionesRadar8 = objRet.opciones;
          var myObject = eval('(' + objRet.imagen + ')');
          console.log('<<>>myObject = ' + JSON.stringify(myObject) + ' <<>>');
          console.log('<<>>opcionesBar = ' + JSON.stringify(objRet.opciones) + ' <<>>');
          this.setState({
            dataChart8: myObject,
            cargando: false,
            botonGrabarHabilitado: JSON.stringify(objRet.botonGrabarHabilitado),
            grafico: objRet.grafico,
            tipoGrafico: objRet.tipoGrafico,
            dato1: objRet.dato1,
            dato2: objRet.dato2,
            dato3: objRet.dato3,
            dato4: objRet.dato4,
            data: JSON.stringify(objRet.data),
            datos: JSON.stringify(objRet.datos),
            imagen: objRet.imagen,
            dataMat: objRet.dataMat,
            opciones: objRet.opciones,
          });
          console.log('******### SALIDA FUERA OBJRET.dataChart8 ###****** ' +
          this.state.dataChart8);
      }.bind(this));
    })();
  }

  handleInputNomRatioxCompania = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomRatioxClasificador  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomRatioxAnioMes  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomRatioxRamo  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomAlertaxCompania  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomAlertaxClasificador  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomAlertaxAnioMes  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomAlertaxRamo  = (e) => {
    const {name, value} = e.target;
    this.setState({nomCuadro: value});
  }

  handleInputNomPresentacionChange = (e) => {
    const {name, value} = e.target;
    this.setState({nomPresentacion: value});
    //setaseguradora({...aseguradora, [name]: value});
  }

  grabarRatioxCompania_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objPresentacion = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart1,
        opciones: this.state.opciones,
      };
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Debe ingresar nombre de cuadro para grabar');
    }
  }

  grabarRatioxClasificador_Click = () => {
    var vArrPresentacion = this.state
    .arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objPresentacion = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart2,
        opciones: this.state.opciones,
      };
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
    vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro ramoSeleccionado para grabar');
    } else {
      alert(  'Debe ingresar nombre de cuadro para grabar');
    }
  }

  grabarRatioxAnioMes_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objPresentacion = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart3,
        opciones: this.state.opciones,
      };
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Debe ingresar nombre de cuadro para grabar');
    }
  }

  grabarRatioxRamo_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objPresentacion = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart4,
        opciones: this.state.opciones,
      };
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Debe ingresar nombre de cuadro para grabar');
    }
  }


  grabarAlertaxCompania_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      objPresentacion = {
        nomCuadro: this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart5,
        opciones: this.state.opciones,
      }
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Debe ingresar nombre de cuadro para grabar');
    }
  }

  grabarAlertaxClasificador_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      objPresentacion = {
        nomCuadro: this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart6,
        opciones: this.state.opciones,
      };
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Para grabar debe ingresar nombre de cuadro');
    }
  }

  grabarAlertaxAnioMes_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      objPresentacion = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart7,
        opciones: this.state.opciones,
      };
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Para grabar debe ingresar nombre de cuadro');
    }
  }

  grabarAlertaxRamo_Click = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var vdatosalida = this.state.datosalida;
    var objPresentacion = {};
    var objSalida = {};
    if(this.state.nomCuadro.trim()!=='') {
      objSalida = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
      };
      objPresentacion = {
        nomCuadro : this.state.nomCuadro,
        grafico: this.state.grafico,
        tipoGrafico: this.state.tipoGrafico,
        dato1: this.state.dato1,
        dato2: this.state.dato2,
        dato3: this.state.dato3,
        dato4: this.state.dato4,
        imagen: this.state.imagen,
        datos: this.state.datos,
        dataChart: this.state.dataChart8,
        opciones: this.state.opciones,
      };
      vArrPresentacion.push(objPresentacion);
      vdatosalida.push(objSalida);
      this.setState({arrPresentacion: vArrPresentacion, datosalida: vdatosalida});
      alert('Cuadro seleccionado para grabar');
    } else {
      alert('Debe ingresar nombre del cuadro para seleciona');
    }
  }

    generarImagenRatioxCompania_Click = () => {
      const canvasSave = document.getElementById('chart1');
      canvasSave.toBlob(function (blob) {
          saveAs(blob, "RatioxCompania.png");
      });
    }
    generarCSVRatioxCompania_Click = () => {
      var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
      saveAs(blob, "RatioxCompania.csv");
    }

  generarImagenRatioxClasificador_Click = () => {
    const canvasSave = document.getElementById('chart2');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "RatioxClasificador.png")
    })
  }

  generarCSVRatioxClasificador_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "RatioxClasificador.csv");
  }

  generarImagenRatioxAnioMes_Click = () => {
    const canvasSave = document.getElementById('chart3');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "RatioxAnioMes.png")
    });
  }

  generarCSVRatioxAnioMes_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "RatioxAnioMes.csv");
  }

  generarImagenRatioxRamo_Click = () => {
    const canvasSave = document.getElementById('chart4');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "RatioxRamo.png")
    })
  }

  generarCSVRatioxRamo_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "RatioxRamo.csv");
  }


  generarImagenAlertaxCompania_Click = () => {
    const canvasSave = document.getElementById('chart5');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "AlertaxCompania.png")
    })
  }

  generarCSVAlertaxCompania_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "AlertaxCompania.csv");
  }

  generarImagenAlertaxClasificador_Click = () => {
    const canvasSave = document.getElementById('chart6');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "AlertaxClasificador.png")
    })
  }

  generarCSVAlertaxClasificador_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "AlertaxClasificador.csv");
  }

  generarImagenAlertaxAnioMes_Click = () => {
    const canvasSave = document.getElementById('chart7');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "AlertaxAnioMes.png")
    })
  }

  generarCSVAlertaxAnioMes_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "AlertaxAnioMes.csv");
  }

  generarImagenAlertaxRamo_Click = () => {
    const canvasSave = document.getElementById('chart8');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "AlertaxRamo.png")
    })
  }

  generarCSVAlertaxRamo_Click = () => {
    var blob = new Blob([this.state.dataMat], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "AlertaxRamo.csv");
  }

  generarBase64RatioxCompania = () => {
    //alert('Graba aqui Base64: ');
    //var base64Image = this.chartReference.current.chartInstance.toBase64Image();
    //console.log(base64Image);
  }

  graficarRanxC = () => {
      this.setState({dataChart9: null });
      var cias='';
      var ams='';
      var cams='';
      cias = retornoLista(this.state.cias9);
      ams = retornoLista(this.state.ams9);
      var body_stringify = JSON.stringify({
        codcialista: cias,
        codramo: this.state.ramo9,
        aniomeslista: ams,
        codclasranking: this.state.claran9
      });
      var ctoken='pepito';
      console.log("GraficarRanxC body = " + body_stringify);
      var url = properties.url + properties.rankingxcia;
      rest_postQuery(url, ctoken, body_stringify, function(response){
        console.log('response=' + JSON.stringify(response));
      });
  }

  graficarRanxI = () => {
    this.setState({dataChart10: null });
    var ams='';
    var clarans='';
    ams = retornoLista(this.state.ams10);
    clarans = retornoLista(this.state.clarans10);
    var body_stringify = JSON.stringify({
      codcia: this.state.cia10,
      codramo: this.state.ramo10,
      aniomeslista: ams,
      codclasrankinglista: clarans
    });
    var ctoken='pepito';
    console.log("GraficarRanxI body = " + body_stringify);
    var url = properties.url + properties.rankingxratios;
    rest_postQuery(url, ctoken, body_stringify, function(response){
      console.log('response=' + JSON.stringify(response));
    });
  }

  graficarRanxM = () => {
    this.setState({dataChart11: null });
    var cias='';
    var ams='';
    cias = retornoLista(this.state.cias11);
    ams = retornoLista(this.state.ams11);
    var body_stringify = JSON.stringify({
      codcialista: cias,
      codramo: this.state.ramo11,
      aniomeslista: ams,
      codclasranking: this.state.claran11
    });
    var ctoken='pepito';
    console.log("GraficarRanxM body = " + body_stringify);
    var url = properties.url + properties.rankingxmes;
    rest_postQuery(url, ctoken, body_stringify, function(response){
      console.log('response=' + JSON.stringify(response));
    });
  }

  graficarRanxR = () => {
    this.setState({dataChart12: null });
    var ramos='';
    var ams='';
    ramos = retornoLista(this.state.ramos12);
    ams = retornoLista(this.state.ams12);
    var body_stringify = JSON.stringify({
      codcia: this.state.cia12,
      codramolista: ramos,
      aniomeslista: ams,
      codclasranking: this.state.claran12
    });
    var ctoken='pepito';
    console.log("GraficarRanxM body = " + body_stringify);
    var url = properties.url + properties.rankingxramo;
    rest_postQuery(url, ctoken, body_stringify, function(response){
      console.log('response=' + JSON.stringify(response));
    });
  }

  grabarPresentacion = () => {
    var objPresentacion = {};
    var objPresentacionDetalle = null;
    var vcodpres = '';
    var token = '123';
    var i = 0;
    var n = 0;
    var vArrPresentacion = this.state.arrPresentacion;
    var vDatoSalida = this.state.datosalida;
    var vdato1="";
    var vdato2="";
    var vdato3="";
    var vdato4="";
    var url = '';
    if(this.state.nomPresentacion.trim()!=='') {
      if(this.state.grabando) {
        url = properties.url + properties.presentacioninserta;
        console.log('URL; ' + url);
        presentacionInsertaRest(url, token, this.state.nomPresentacion.trim(), this.props.login.codempresa, function(response) {
           //console.log("Respuesta " + JSON.stringify(response));
           //console.log("codpres = " + JSON.stringify(response.data.codpres));
           //console.log("result = " + JSON.stringify(response.result));
           if (JSON.stringify(response.result).replaceAll('"','') === 'OK') {
             vcodpres = JSON.stringify(response.data.codpres);
             url = properties.url + properties.presentaciondetalleinserta;
             console.log('URL DETALLE = ' + url);
             for(i=0;i<vArrPresentacion.length;i++) {
               objPresentacion = vArrPresentacion[i];
               if(objPresentacion.dato1) {
                 vdato1 = objPresentacion.dato1.replaceAll('"','');
               } else {
                 vdato1 = "";
               }
               if(objPresentacion.dato2) {
                 vdato2 = objPresentacion.dato2.replaceAll('"','');
               } else {
                 vdato2 = "";
               }
               if(objPresentacion.dato3) {
                 vdato3 = objPresentacion.dato3.replaceAll('"','');
               } else {
                 vdato3 = "";
               }
               if(objPresentacion.dato4) {
                 vdato4 = objPresentacion.dato4.replaceAll('"','');
               } else {
                 vdato4 = "";
               }
               presentaciondetalleInsertaRest(
                 url,
                 token,
                 vcodpres,
                 objPresentacion.nomCuadro,
                 objPresentacion.grafico,
                 objPresentacion.tipoGrafico,
                 vdato1,
                 vdato2,
                 vdato3,
                 vdato4,
                 JSON.stringify(objPresentacion.opciones),
                 vArrPresentacion[i].imagen,
                 vArrPresentacion[i].datos,
                 JSON.stringify(objPresentacion.dataChart),
                 (i+1), function(response2) {
                   console.log(i + '********) resp2 = ' + JSON.stringify(response2));
                   if (JSON.stringify(response2.result).replaceAll('"','') !== 'OK') {
                     n++;
                   }
               });
             }
             if(n>0) {
               alert('Mas de 1 dato no quedaron grabados');
             } else {
               alert('Datos Grabados');
             }
           } else {
             alert('Ocurrió un problema al registrar la presentación!!');
           }
        });
        this.setState({grabando: false, botonGrabacion: 'Comenzar grabación', datosalida: [], arrPresentacion: [] });
        //alert('Terminó la grabación');
      } else {
        this.setState({grabando: true, botonGrabacion: 'Terminar grabación', datosalida: [], arrPresentacion: [] });
        alert('Grabando');
      }
    } else {
      alert('Para comenzar a grabar debe ingresar el nombre de la presentación');
    }
  }

  comenzar = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var i=0;
    console.log('LENGTH' + vArrPresentacion.length + ' I=' + i);
    if (vArrPresentacion.length > 0) {
      this.setState({
        nomCuadro : vArrPresentacion[i].nomCuadro,
        grafico: vArrPresentacion[i].grafico,
        tipoGrafico: vArrPresentacion[i].tipoGrafico,
        dato1: vArrPresentacion[i].dato1,
        dato2: vArrPresentacion[i].dato2,
        dato3: vArrPresentacion[i].dato3,
        dato4: vArrPresentacion[i].dato4,
        imagen: vArrPresentacion[i].imagen,
        datos: vArrPresentacion[i].datos,
        text: vArrPresentacion[i].text,
        dataChart: eval('(' + vArrPresentacion[i].imagen + ')'),
        opciones: vArrPresentacion[i].opciones,
        pos: i,
      });
    }
    console.log('state.dataChart=' + this.state.tipoGrafico);
  }

  anterior = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var i=this.state.pos;
    if(i>0) {
      i--;
    }
    console.log('I=' + i);
    if (vArrPresentacion.length > 0) {
      this.setState({
        nomCuadro : vArrPresentacion[i].nomCuadro,
        grafico: vArrPresentacion[i].grafico,
        tipoGrafico: vArrPresentacion[i].tipoGrafico,
        dato1: vArrPresentacion[i].dato1,
        dato2: vArrPresentacion[i].dato2,
        dato3: vArrPresentacion[i].dato3,
        dato4: vArrPresentacion[i].dato4,
        imagen: vArrPresentacion[i].imagen,
        datos: vArrPresentacion[i].datos,
        text: vArrPresentacion[i].text,
        dataChart: eval('(' + vArrPresentacion[i].imagen + ')'),
        opciones: vArrPresentacion[i].opciones,
        pos: i,
      });
    }
    console.log('state.dataChart=' + this.state.tipoGrafico);
  }

  siguiente = () => {
    var vArrPresentacion = this.state.arrPresentacion;
    var i=this.state.pos;
    if(i<(vArrPresentacion.length-1)) {
      i++;
    }
    console.log('I=' + i);
    if (vArrPresentacion.length > 0) {
      this.setState({
        nomCuadro : vArrPresentacion[i].nomCuadro,
        grafico: vArrPresentacion[i].grafico,
        tipoGrafico: vArrPresentacion[i].tipoGrafico,
        dato1: vArrPresentacion[i].dato1,
        dato2: vArrPresentacion[i].dato2,
        dato3: vArrPresentacion[i].dato3,
        dato4: vArrPresentacion[i].dato4,
        imagen: vArrPresentacion[i].imagen,
        datos: vArrPresentacion[i].datos,
        text: vArrPresentacion[i].text,
        dataChart: eval('(' + vArrPresentacion[i].imagen + ')'),
        opciones: vArrPresentacion[i].opciones,
        pos: i,
      });
    }
    //console.log('state.dataChart=' + this.state.tipoGrafico);
  }

  render() {

    return (
      <div>
        <Typography variant="h7" component="h5">
          <Tabs onSelect={index => this.cambioTab(index)}>
            <TabList>
              { this.state.graficar===1 &&
                <Tab>Ratio x Cia</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Ratio x Clas</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Ratio x Mes</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Ratio x Ramo</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Alerta x Cia</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Alerta x Clas</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Alerta x Mes</Tab>
              }
              { this.state.graficar===1 &&
                <Tab>Alerta x Ramo</Tab>
              }
              { this.state.grabar===1 &&
                <Tab>Grabación</Tab>
              }
              { (this.state.ver===1 || this.state.grabar===1) &&
                <Tab>Presentación</Tab>
              }
            </TabList>
            <TabPanel>
              <center>
               <h3 style={{color: "#015588"}}>Ratios por Compañía</h3>
              </center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                       {this.state.botonGrabarHabilitado &&
                         <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                           <IconButton color="secondary" onClick={this.generarImagenRatioxCompania_Click}>
                             <AssessmentIcon />
                           </IconButton>
                         </Tooltip>
                       }
                    </td>
                    <td >
                       {this.state.botonGrabarHabilitado &&
                         <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                           <IconButton style={{ color: '#007700' }} onClick={this.generarCSVRatioxCompania_Click}>
                             <TableChartTwoToneIcon />
                           </IconButton>
                         </Tooltip>
                       }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomRatioxCompania" name="nomRatioxCompania"
                          label="Nombre cuadro" fullWidth required
                          style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomRatioxCompania}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                          <IconButton style={{ color: '#000000' }} onClick={this.grabarRatioxCompania_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañías: </label></td>
                    <td style={{ width: '15%' }}><CompaniaMulti callbackFromParent={this.myCallback} cuadro="1" dato="cias1"/></td>
                    <td style={{ width: '7%' }}><label>Ramo: </label></td>
                    <td style={{ width: '15%' }}><Ramo callbackFromParent={this.myCallback} cuadro="1" dato="ramo1" /></td>
                    <td style={{ width: '7%' }}><label>Periodos: </label></td>
                    <td style={{ width: '15%' }}><AnioMesMulti callbackFromParent={this.myCallback} cuadro="1" dato="ams1" /></td>
                    <td style={{ width: '7%' }}><label>Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasind callbackFromParent={this.myCallback} cuadro="1" dato="nomind1" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarRatioxCompania_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart1!=null &&
                  <Bar id="chart1" width={100} height={31} data={this.state.dataChart1} options={this.state.opciones} ref={this.chartReference}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Ratios por Clasificación de Indicador</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                       {this.state.botonGrabarHabilitado &&
                         <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                           <IconButton color="secondary" onClick={this.generarImagenRatioxClasificador_Click}>
                             <AssessmentIcon />
                           </IconButton>
                         </Tooltip>
                       }
                    </td>
                    <td >
                       {this.state.botonGrabarHabilitado &&
                         <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                           <IconButton style={{ color: '#007700' }} onClick={this.generarCSVRatioxClasificador_Click}>
                             <TableChartTwoToneIcon />
                           </IconButton>
                         </Tooltip>
                       }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomRatioxClasificador" name="nomRatioxClasificador"
                          label="Nombre cuadro" fullWidth required
                           style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomRatioxClasificador}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                          <IconButton style={{ color: '#000000' }} onClick={this.grabarRatioxClasificador_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañía: </label></td>
                    <td style={{ width: '15%' }}><Compania callbackFromParent={this.myCallback} cuadro="2" dato="cia2" /></td>
                    <td style={{ width: '7%' }}><label>Ramo: </label></td>
                    <td style={{ width: '15%' }}><Ramo callbackFromParent={this.myCallback} cuadro="2" dato="ramo2" /></td>
                    <td style={{ width: '7%' }}><label>Periodos: </label></td>
                    <td style={{ width: '15%' }}><AnioMesMulti callbackFromParent={this.myCallback} cuadro="2" dato="ams2" /></td>
                    <td style={{ width: '7%' }}><label>Grupo Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasificadores callbackFromParent={this.myCallback} cuadro="2" dato="clasif2" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarRatioxClasificador_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart2!=null &&
                  <Bar id="chart2" width={100} height={32} data={this.state.dataChart2} options={this.state.opciones} ref={(ref) => this.Bar = ref}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Ratios por Año y Mes</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                         <IconButton color="secondary" onClick={this.generarImagenRatioxAnioMes_Click}>
                           <AssessmentIcon />
                         </IconButton>
                       </Tooltip>
                     }
                    </td>
                    <td >
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                         <IconButton style={{ color: '#007700' }} onClick={this.generarCSVRatioxAnioMes_Click}>
                           <TableChartTwoToneIcon />
                         </IconButton>
                       </Tooltip>
                     }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomRatioxAnioMes" name="nomRatioxAnioMes"
                          label="Nombre cuadro" fullWidth required
                           style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomRatioxAnioMes}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                          <IconButton style={{ color: '#000000' }} onClick={this.grabarRatioxAnioMes_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañías: </label></td>
                    <td style={{ width: '15%' }}><CompaniaMulti callbackFromParent={this.myCallback} cuadro="3" dato="cias3" /></td>
                    <td style={{ width: '7%' }}><label>Ramo: </label></td>
                    <td style={{ width: '15%' }}><Ramo callbackFromParent={this.myCallback} cuadro="3" dato="ramo3" /></td>
                    <td style={{ width: '7%' }}><label>Periodos: </label></td>
                    <td style={{ width: '15%' }}><AnioMesMulti callbackFromParent={this.myCallback} cuadro="3" dato="ams3" /></td>
                    <td style={{ width: '7%' }}><label>Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasind callbackFromParent={this.myCallback} cuadro="3" dato="nomind3" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarRatioxAnioMes_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart3!=null &&
                  <Line id="chart3" width={100} height={31} options={this.state.opciones} data={this.state.dataChart3}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Ratios por Ramo</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                       {this.state.botonGrabarHabilitado &&
                         <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                           <IconButton color="secondary" onClick={this.generarImagenRatioxRamo_Click}>
                             <AssessmentIcon />
                           </IconButton>
                         </Tooltip>
                       }
                    </td>
                    <td>
                       {this.state.botonGrabarHabilitado &&
                         <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                           <IconButton style={{ color: '#007700' }} onClick={this.generarCSVRatioxRamo_Click}>
                             <TableChartTwoToneIcon />
                           </IconButton>
                         </Tooltip>
                       }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomRatioxRamo" name="nomRatioxRamo"
                          label="Nombre cuadro" fullWidth required
                           style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomRatioxRamo}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                          <IconButton style={{ color: '#000000' }} onClick={this.grabarRatioxRamo_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañía: </label></td>
                    <td style={{ width: '15%' }}><Compania callbackFromParent={this.myCallback} cuadro="4" dato="cia4" /></td>
                    <td style={{ width: '7%' }}><label>Ramos: </label></td>
                    <td style={{ width: '15%' }}><RamoMulti callbackFromParent={this.myCallback} cuadro="4" dato="ramos4" /></td>
                    <td style={{ width: '7%' }}><label>Periodos: </label></td>
                    <td style={{ width: '15%' }}><AnioMesMulti callbackFromParent={this.myCallback} cuadro="4" dato="ams4" /></td>
                    <td style={{ width: '7%' }}><label>Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasind callbackFromParent={this.myCallback} cuadro="4" dato="nomind4" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarRatioxRamo_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart4!=null &&
                  <Bar id="chart4" width={100} height={31} data={this.state.dataChart4} options={this.state.opciones} ref={this.chartReference}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Alerta por Compañía</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                <tr>
                  <td >
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                         <IconButton color="secondary" onClick={this.generarImagenAlertaxCompania_Click}>
                           <AssessmentIcon />
                         </IconButton>
                       </Tooltip>
                     }
                  </td>
                  <td>
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                         <IconButton style={{ color: '#007700' }} onClick={this.generarCSVAlertaxCompania_Click}>
                           <TableChartTwoToneIcon />
                         </IconButton>
                       </Tooltip>
                     }
                  </td>
                  <td>
                    {this.state.grabando === true ? (
                      <Blink color='purple' text='Grabando' fontSize='24'>
                      </Blink>
                    ) : null}
                  </td>
                  <td>
                    {this.state.grabando  && this.state.botonGrabarHabilitado &&
                      <TextField
                        id="nomAlertaxCompania" name="nomAlertaxCompania"
                        label="Nombre cuadro" fullWidth required
                         style = {{
                          width: 500
                        }}
                        inputProps={{
                          maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                        }}
                        onChange={this.handleInputNomAlertaxCompania}
                      />
                    }
                  </td>
                  <td>
                    {this.state.grabando  && this.state.botonGrabarHabilitado &&
                      <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                        <IconButton style={{ color: '#000000' }} onClick={this.grabarAlertaxCompania_Click}>
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </td>
                </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañías: </label></td>
                    <td style={{ width: '15%' }}><CompaniaMulti callbackFromParent={this.myCallback} cuadro="5" dato="cias5" /></td>
                    <td style={{ width: '7%' }}><label>Ramo: </label></td>
                    <td style={{ width: '15%' }}><Ramo callbackFromParent={this.myCallback} cuadro="5" dato="ramo5" /></td>
                    <td style={{ width: '7%' }}><label>Periodo: </label></td>
                    <td style={{ width: '15%' }}><AnioMes callbackFromParent={this.myCallback} cuadro="5" dato="am5" /></td>
                    <td style={{ width: '7%' }}><label>Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasind callbackFromParent={this.myCallback} cuadro="5" dato="nomind5" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarAlertaxCompania_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart5!=null &&
                  <Radar id="chart5" width={100} height={31} data={this.state.dataChart5} options={this.state.opciones}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Alerta por Clasificación de Indicador</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                         <IconButton color="secondary" onClick={this.generarImagenAlertaxClasificador_Click}>
                           <AssessmentIcon />
                         </IconButton>
                       </Tooltip>
                     }
                    </td>
                    <td>
                      {this.state.botonGrabarHabilitado &&
                        <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                          <IconButton style={{ color: '#007700' }} onClick={this.generarCSVAlertaxClasificador_Click}>
                            <TableChartTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomAlertaxClasificador" name="nomAlertaxClasificador"
                          label="Nombre cuadro" fullWidth required
                           style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomAlertaxClasificador}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                          <IconButton style={{ color: '#000000' }} onClick={this.grabarAlertaxClasificador_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañía: </label></td>
                    <td style={{ width: '15%' }}><Compania callbackFromParent={this.myCallback} cuadro="6" dato="cia6" /></td>
                    <td style={{ width: '7%' }}><label>Ramo: </label></td>
                    <td style={{ width: '15%' }}><Ramo callbackFromParent={this.myCallback} cuadro="6" dato="ramo6" /></td>
                    <td style={{ width: '7%' }}><label>Periodo: </label></td>
                    <td style={{ width: '15%' }}><AnioMes callbackFromParent={this.myCallback} cuadro="6" dato="am6" /></td>
                    <td style={{ width: '7%' }}><label>Grupo Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasificadores callbackFromParent={this.myCallback} cuadro="6" dato="claind6" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarAlertaxClasificador_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart6!=null &&
                  <Radar id="chart6" width={100} height={31} data={this.state.dataChart6} options={this.state.opciones}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Alerta por Año y Mes</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                         <IconButton color="secondary" onClick={this.generarImagenAlertaxAnioMes_Click}>
                           <AssessmentIcon />
                         </IconButton>
                       </Tooltip>
                     }
                    </td>
                    <td>
                      {this.state.botonGrabarHabilitado &&
                        <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                          <IconButton style={{ color: '#007700' }} onClick={this.generarCSVAlertaxAnioMes_Click}>
                            <TableChartTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomAlertaxAnioMes" name="nomAlertaxAnioMes"
                          label="Nombre cuadro" fullWidth required
                          style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomAlertaxAnioMes}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                         <IconButton style={{ color: '#000000' }} onClick={this.grabarAlertaxAnioMes_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañía: </label></td>
                    <td style={{ width: '15%' }}><Compania callbackFromParent={this.myCallback} cuadro="7" dato="cia7" /></td>
                    <td style={{ width: '7%' }}><label>Ramo: </label></td>
                    <td style={{ width: '15%' }}><Ramo callbackFromParent={this.myCallback} cuadro="7" dato="ramo7" /></td>
                    <td style={{ width: '7%' }}><label>Periodos: </label></td>
                    <td style={{ width: '15%' }}><AnioMesMulti callbackFromParent={this.myCallback} cuadro="7" dato="ams7" /></td>
                    <td style={{ width: '7%' }}><label>Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasind callbackFromParent={this.myCallback} cuadro="7" dato="nomind7" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarAlertaxAnioMes_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart7!=null &&
                  <Radar id="chart7" width={100} height={31} data={this.state.dataChart7} options={this.state.opciones}/>
              }
            </TabPanel>
            <TabPanel>
              <center><h3 style={{color: "#015588"}}>Alerta por Ramo</h3></center>
              <table style={{ width: '100%' }}>
                <tbody style={{ width: '100%' }}>
                  <tr>
                    <td >
                     {this.state.botonGrabarHabilitado &&
                       <Tooltip title="Descarga Imagen" aria-label="Genera Imagen" placement="top-start">
                         <IconButton color="secondary" onClick={this.generarImagenAlertaxRamo_Click}>
                           <AssessmentIcon />
                         </IconButton>
                       </Tooltip>
                     }
                    </td>
                    <td>
                      {this.state.botonGrabarHabilitado &&
                        <Tooltip title="Descarga Excel" aria-label="Genera CVS" placement="top-start">
                          <IconButton style={{ color: '#007700' }} onClick={this.generarCSVAlertaxRamo_Click}>
                            <TableChartTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                    <td>
                      {this.state.grabando === true ? (
                        <Blink color='purple' text='Grabando' fontSize='24'>
                        </Blink>
                      ) : null}
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <TextField
                          id="nomAlertaxRamo" name="nomAlertaxRamo"
                          label="Nombre cuadro" fullWidth required
                          style = {{
                            width: 500
                          }}
                          inputProps={{
                            maxLength: 40, pattern: "[AZaz0-9]", width: "40",
                          }}
                          onChange={this.handleInputNomAlertaxRamo}
                        />
                      }
                    </td>
                    <td>
                      {this.state.grabando  && this.state.botonGrabarHabilitado &&
                        <Tooltip title="Grabar" aria-label="Grabar" placement="top-start">
                         <IconButton style={{ color: '#000000' }} onClick={this.grabarAlertaxRamo_Click}>
                            <SaveIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '7%' }}><label>Compañía: </label></td>
                    <td style={{ width: '15%' }}><Compania callbackFromParent={this.myCallback} cuadro="8" dato="cia8" /></td>
                    <td style={{ width: '7%' }}><label>Ramos: </label></td>
                    <td style={{ width: '15%' }}><RamoMulti callbackFromParent={this.myCallback} cuadro="8" dato="ramos8" /></td>
                    <td style={{ width: '7%' }}><label>Periodos: </label></td>
                    <td style={{ width: '15%' }}><AnioMesMulti callbackFromParent={this.myCallback} cuadro="8" dato="ams8" /></td>
                    <td style={{ width: '7%' }}><label>Ratio: </label></td>
                    <td style={{ width: '15%' }}><Clasind callbackFromParent={this.myCallback} cuadro="8" dato="nomind8" /></td>
                    <td style={{ width: '10%' }}>
                      <Button variant="outlined" style={{marginLeft: 25, width: 85, height: 35 }} color="primary" onClick={this.graficarAlertaxRamo_Click} >Graficar</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.cargando &&
                <center><CircularProgress/></center>
              }
              {
                this.state.dataChart8!=null &&
                  <Radar id="chart8" width={100} height={31} data={this.state.dataChart8} options={this.state.opciones}/>
              }
            </TabPanel>
            {this.state.grabar &&
              <TabPanel>
                <table style={{ width: '100%' }}>
                  <tr style={{ width: '100%' }}>
                   <td style={{ width: '100%', justify: 'center' }}>
                     <TextField
                       id="nomPresentacion"
                       name="nomPresentacion"
                       label="Nombre presentación"
                       defaultValue=""
                       helperText=""
                       fullWidth
                       required
                       style = {{
                         marginTop: -2,
                       }}
                       inputProps={{
                         maxLength: 50,
                         pattern: "[a-z A-Z.0-9]",
                         width: "160",
                       }}
                       onChange={this.handleInputNomPresentacionChange}
                       value={this.state.nompres}
                     />
                   </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '100%', justify: 'center' }}>
                      <Button
                        style = {{
                          background: 'linear-gradient(45deg, #25B48B 30%, #258E53 90%)',
                          color: '#FEF9E7',
                          height: '100%',
                          marginLeft: 650,
                          justifyContent: 'center',
                          textTransform: 'capitalize',
                        }}
                        onClick={this.grabarPresentacion}>
                        {this.state.botonGrabacion}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Tabla
                        updateData={val => this.updateData(val)}
                        titulo={this.state.titulox.toUpperCase()}
                        datosheader={this.state.headsalida}
                        datostabla={this.state.datosalida}
                      />
                    </td>
                  </tr>
                </table>
              </TabPanel>
            }
            { (this.state.ver===1 || this.state.grabar===1) &&
              <TabPanel>
                <Presentacion callbackFromParent={this.myCallback} cuadro="10" dato="pres10" />
                <center><h3 style={{color: "#015588", fontSize: 16, fontWeight: 'bold'}}>{this.state.grafico} - {this.state.nomCuadro}</h3></center>
                <center><label style={{ color: '#007700', fontSize: 12 }}>{this.state.text}</label></center>
                {this.state.cargando &&
                  <center><CircularProgress/></center>
                }
                {
                  this.state.tipoGrafico==='linea' &&
                    <Line width={100} height={27} data={this.state.dataChart} options={this.state.opciones} ref={this.chartReference}/> //3
                }
                {
                  this.state.tipoGrafico==='barra' &&
                    <Bar width={100} height={27} data={this.state.dataChart} options={this.state.opciones} ref={this.chartReference}/> //1
                }
                {
                  this.state.tipoGrafico==='radar' &&
                    <Radar width={100} height={27} data={this.state.dataChart} options={this.state.opciones} ref={this.chartReference}/> //8
                }
                <Tooltip title="Anterior" aria-label="Anterior" placement="top-start">
                 <IconButton style={{ color: '#0000FF' }} onClick={this.anterior}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Comenzar" aria-label="Comenzar" placement="top-start">
                 <IconButton style={{ color: '#0000FF' }} onClick={this.comenzar}>
                    <PlayCircleFilledIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Siguiente" aria-label="Siguiente" placement="top-start">
                 <IconButton style={{ color: '#0000FF' }} onClick={this.siguiente}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Tooltip>
              </TabPanel>
            }
          </Tabs>
        </Typography>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.loginObj,
    presentacion: state.presentacionObj,
  };
}

export default connect(
   mapStateToProps, {
     obtieneLogin,
     obtienePresentacion,
     grabaPresentacion,
})(Home);
