import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';


export function graficarRatioxAnioMes (
  cias3,
  ams3,
  ramo3,
  nomind3,
  ramolabel,
  indlabel,
  callback
  ) {
  var objRet = {};
  var opcionesBar3 = {
    elements: {
      rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Ratios por año mes"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  }
  var cias='';
  var ccias='';
  var cams='';
  var data = [];
  var dataMat = '';
  var ams = '';
  var arrams = [];
  var arramv = [];
  var arrciasl = [];
  var arrciasv = [];
  var datacia = [];
  var i = 0;
  var j = 0;
  var cnt = 0;
  var datocomp1 = 0;
  var datocomp2 = 0;
  var datocomp3 = 0;
  var datocomp4 = 0;
  var indice = 0.00;
  var vgrafico = "";
  var vtipoGrafico = "";
  var vdato1 = "";
  var vdato2 = "";
  var vdato3 = "";
  var vdato4 = "";
  cias = retornoLista(cias3);
  ccias = retornoListaLabel(cias3);
  //cams = retornoListaLabel(ams3);
  ams = retornoLista(ams3);
  //arrams = retornoArregloLabel(ams3);

  arramv = retornoArregloValor(ams3);
  arramv = arramv.sort();
  arrams = retornaFechasLiteralDeArreglo(arramv.sort());
  cams = stringSeparadoPorComasDeArreglo(arrams);
  //arrciasl = retornoArregloLabel(cias3);
  //arrciasv = retornoArregloValor(cias3);
  arrciasv = retornoArregloValorOrdenadoPorLabel(cias3);
  arrciasl = retornoArregloLabelDeCadenaJSON(cias3);

  var body_stringify = JSON.stringify({
    codcialista: cias,
    codramo: ramo3,
    aniomeslista: ams,
    codind: nomind3
  });

  var ctoken='pepito';
  var url = properties.url + properties.ratioxmes;
  console.log("Arreglos arrcias = " + ccias + " arrams = " + cams);
  console.log("graficarRatioxAnioMes body = " + body_stringify);
  rest_postQuery(url, ctoken, body_stringify, function(response) {
    datacia = response[0];
    //console.log('response = ' + JSON.stringify(response[0]));
    for(j=0;j<arrciasv.length;j++) {
      data[j]='';
    }
    for(cnt=0;cnt<eval(datacia).length;cnt++) {
      indice = JSON.stringify(datacia[cnt].indice).replace('"','').replace('"','');
      console.log('INDICE = ' + indice);
      datocomp2 =  parseInt(JSON.stringify(datacia[cnt].aniomes));
      datocomp4 = parseInt(JSON.stringify(datacia[cnt].codcia));
      for(i=0;i<arramv.length;i++) {
        for(j=0;j<arrciasv.length;j++) {
          datocomp1 = parseInt(arramv[i]);
          datocomp3 = parseInt(arrciasv[j]);
          if(datocomp1===datocomp2 &&
            datocomp3===datocomp4) {
            data[j] = data[j] + ',' + indice;
            console.log('OKOKOKOK indice =' + indice + " data[j]=" + data[j] + "JJJJ = " + j);
          }
        }
      }
    }
    var color='';
    opcionesBar3.title.text = opcionesBar3.title.text +
      ' Ramo: ' +
      ramolabel.replaceAll('"','') +
      ' Indicador: ' +
      indlabel.replaceAll('"','');
    var datosGraf = "{";
    datosGraf = datosGraf + "  labels: [" + cams + "],";
    datosGraf = datosGraf + "  datasets: [";
    for(i=0;i<arrciasl.length;i++) {
      console.log("DIBUJAND data[i]=>" + data[i]);
      color = colores(i);
      datosGraf = datosGraf + "    {";
      datosGraf = datosGraf + "    label: \"" + arrciasl[i] + "\",";
      datosGraf = datosGraf + "    fill: false,";
      datosGraf = datosGraf + "    linTension: 0.2,";
      datosGraf = datosGraf + "    borderColor: \"" + color + "\",";
      //data = obtieneIndicesxCia(JSON.stringify(response),arrciasv[i]);
      datosGraf = datosGraf + "    data: [";
      datosGraf = datosGraf + "            " + data[i].substring(1);
      datosGraf = datosGraf + "          ],";
      datosGraf = datosGraf + "    },";

      /* INICIO DATA PARA CSV */
      if (i===0) {
        //dataMat[0] = "Periodo";
        dataMat = "Ratio por año mes;Ramo;" + ramolabel.replaceAll('"','') + ";Indicador;" + indlabel.replaceAll('"','') + "\n";
        dataMat = dataMat + "Compañias";
        for(j=0;j<arrams.length;j++) {
          dataMat = dataMat + ";" + arrams[j];
        }
        dataMat = dataMat + "\n";
      }
      //dataMat[i+1] = arrams[i];
      dataMat = dataMat + arrciasl[i];
      //for(j=0;j<data.length;j++) {
        //dataMat[i+1] = dataMat[i+1] + "," + data[j];
        dataMat = dataMat + ";" + data[i].substring(1).replaceAll(',',';');
      //}
      dataMat = dataMat + "\n";
      /* FIN DATA PARA CSV */


    }
    datosGraf = datosGraf + "  ]";
    datosGraf = datosGraf + "}";
    //alert(datosGraf);
//    console.log('<<<<<<>>>>>>datosGraf = ' + datosGraf);
    vgrafico = "Ratios por Año y Mes";
    vtipoGrafico = "linea";
    vdato1 = "Compañia(s): " + ccias;
    vdato2 = "Ramo: " + ramolabel;
    vdato3 = "Periodo(s): " + cams;
    vdato4 = "Indicador: " + indlabel;
    var myObject = eval('(' + datosGraf + ')');
    objRet = {
      dataChart3: myObject,
      cargando: false,
      botonGrabarHabilitado: true,
      grafico: vgrafico,
      tipoGrafico: vtipoGrafico,
      dato1: vdato1,
      dato2: vdato2,
      dato3: vdato3,
      dato4: vdato4,
      imagen: datosGraf,
      data: data,
      datos: data,
      dataMat: dataMat,
      opciones: opcionesBar3,
    }
    //console.log("RETONNNNNNADNNNNDOOO");
    callback(objRet);
  });
}
