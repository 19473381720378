import React, { Component } from 'react';
import Select from 'react-select';
import { properties } from './../properties';
import { rest_get } from './../Rests';

class AniomesMulti extends Component {

  constructor(props){
    super(props);
    this.state = {
      opAniomes: [],
      opcionSeleccionada: null,
      selectedOptions: [],
      cuadro: this.props.cuadro,
      dato: this.props.dato,
    }
  }

  getAnioMes() {
    var ctoken='pepito';
    var url = properties.url + properties.aniomes;
    var parametros=  '';
    rest_get(url, ctoken, parametros, function(response) {
       this.setState({ opAniomes: response });
    }.bind(this))
  }

  componentDidMount(){
    this.getAnioMes();
  }


  cambio = selectedOptions => {
    let valores = { cuadro: this.state.cuadro, dato: this.state.dato, valor: selectedOptions }
    this.setState({ valores });
    this.props.callbackFromParent(valores);    
  };



  render() {
    const { selectedOption } = this.state;
    return(
      <Select options={ this.state.opAniomes } autosize={true} value={selectedOption} onChange={this.cambio} isMulti= {true} />
    )
  }
}

export default AniomesMulti;