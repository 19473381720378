import { properties } from '../Home/properties';
import { rest_postQuery } from '../Home/Rests';
import { retornoLista,
  retornoListaLabel,
  retornoArregloLabel,
  retornoArregloValorOrdenadoPorLabel,
  obtieneIndicesxMes,
  retornoArregloValor,
  obtienePuntajexMes,
  colores,
  coloresRadar,
  stringOrdenadoSeparadoPorComas,
  retornaFechasLiteralDeArreglo,
  stringSeparadoPorComasDeArreglo,
  retornoArregloLabelDeCadenaJSON,
  retornoBackgroundColor,
  retornoHoverBackgroundColor,
  retornoBorderColor,
} from '../Home/Funciones';

export function graficarRatioxCompania (
  cias1,
  ams1,
  ramo1,
  nomind1,
  nro,
  ramolabel,
  indlabel,
  callback
  ) {
  var objRet = {}
  var cias='';
  var ccias='';
  var ams='';
  var cams = '';
  var arrcias = [];
  var arrciasv = [];
  var arrciasl = [];
  var arrams = [];
  var arramv = [];
  //var dataMat = [];
  var dataMat = "";
  var data = [];
  var i = 0;
  var j = 0;
  var ctoken='pepito';
  var vnro=nro;
  var url = properties.url + properties.ratioxcia;
  var datosGraf = "";
  var vgrafico = "";
  var vtipoGrafico = "";
  var vdato1 = "";
  var vdato2 = "";
  var vdato3 = "";
  var vdato4 = "";

  const cBackgroundColor = retornoBackgroundColor();
  const cretornoHoverBackgroundColor = retornoHoverBackgroundColor();
  const cBorderColor = retornoBorderColor();
  var opcionesBar1 = {
     elements: {
       rectangle: {
        display: true,
        borderWidth: 2,
        borderSkipped: "bottom"
      }
    },
    responsive: true,
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Ratios por Compañía"
    },
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end"
      }
    }
  };
  cias = retornoLista(cias1);
  //ccias = retornoListaLabel(this.state.cias1);
  ams = retornoLista(ams1);
  arrcias = retornoArregloLabel(cias1);
  arrams = retornoArregloLabel(ams1);
  arramv = retornoArregloValor(ams1);
  arramv = retornoArregloValor(ams1);
  arrams = retornaFechasLiteralDeArreglo(arramv.sort());
  arramv = arramv.sort();
  arrciasv = retornoArregloValorOrdenadoPorLabel(cias1);
  arrciasl = retornoArregloLabelDeCadenaJSON(cias1);
  cams = stringSeparadoPorComasDeArreglo(arrams);
  ccias = stringSeparadoPorComasDeArreglo(arrciasl);
  console.log('aqui arrams ##########' + arrams);
  ams = stringOrdenadoSeparadoPorComas(ams);
  console.log("ams nuev #######" + ams)
  var body_stringify = JSON.stringify({
    codcialista: cias,
    codramo: ramo1,
    aniomeslista: ams,
    codind: nomind1,
  });
  rest_postQuery(url, ctoken, body_stringify, function(response){
    opcionesBar1.title.text = opcionesBar1.title.text +
      ' Ramo: ' +
      ramolabel.replaceAll('"','') +
      ' Ratio: ' +
      indlabel.replaceAll('"','');
      var vdato1="";
      var vdato2="";
      var vdato3="";
      var vdato4="";
      var vgrafico = "";
      var vtipoGrafico = "";
      var datosGraf = "{";
        datosGraf = datosGraf + "  labels: [" + ccias + "],";
        datosGraf = datosGraf + "  datasets: [";
        for(i=0;i<arrams.length;i++) {
          datosGraf = datosGraf + "    {";
          datosGraf = datosGraf + "    label: \"" + arrams[i] + "\",";
          data = obtieneIndicesxMes(JSON.stringify(response),arramv[i]);
          datosGraf = datosGraf + "    data: [";
          datosGraf = datosGraf + "            " + data ;
          datosGraf = datosGraf + "          ],";
          datosGraf = datosGraf + cBackgroundColor;
          datosGraf = datosGraf + cretornoHoverBackgroundColor;
          datosGraf = datosGraf + cBorderColor;
          datosGraf = datosGraf + "    },";
          /* INICIO DATA PARA CSV */
          if (i===0) {
            //dataMat[0] = "Periodo";
            dataMat = "Ratio por compañia;Ramo;" + ramolabel.replaceAll('"','') + ";Ratio;" + indlabel.replaceAll('"','') + "\n";
            dataMat += "Periodo";
            for(j=0;j<data.length;j++) {
              dataMat += ";" + arrciasl[j];
            }
            dataMat += "\n";
          }
          //dataMat[i+1] = arrams[i];
          dataMat = dataMat + arrams[i];
          for(j=0;j<data.length;j++) {
            //dataMat[i+1] = dataMat[i+1] + "," + data[j];
            dataMat = dataMat + ";" + data[j];
          }
          dataMat = dataMat + "\n";
          /* FIN DATA PARA CSV */
          //console.log('DATAMAT: i=' + i + ' arrams[i]' + arrams[i] + ' data = ' + data + 'datalen=' + data.length + ' dataMat[]' + dataMat[i]);
        }
        datosGraf = datosGraf + "  ]";
        datosGraf = datosGraf + "}";
        var myObject = eval('(' + datosGraf + ')');
        vgrafico = "Ratios por Compañía";
        vtipoGrafico = "barra";
        vdato1 = "Compañia(s): " + ccias;
        vdato2 = "Ramo: " + ramolabel
        vdato3 = "Periodo(s): " + cams;
        vdato4 = "Ratio: " + indlabel;
        objRet = {
          dataChart1: myObject,
          cargando: false,
          botonGrabarHabilitado: true,
          grafico: vgrafico,
          tipoGrafico: vtipoGrafico,
          dato1: vdato1,
          dato2: vdato2,
          dato3: vdato3,
          dato4: vdato4,
          data: data,
          datos: data,
          imagen: datosGraf,
          dataMat: dataMat,
          opciones: opcionesBar1,
        }
        //console.log("RETONNNNNNADNNNNDOOO");
        callback(objRet);
      }.bind(this));
    }
