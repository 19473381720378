const settings = {
    //apiLinkRegistro: 'http://localhost:3000/ConfirmacionVista',
    apiLinkRegistro: 'http://www.rutacritica.cl:8083/ConfirmacionVista',
    //api: 'http://locahost/esb-ws/inspeccion/AutoInspeccion.svc'
    //api: 'https://www.hdi.cl/esb-ws/Inspeccion/AutoInspeccion.svc'
    //api: 'http://hdimigqaweb.hdi.cl/esb-ws/Inspeccion/AutoInspeccion.svc'
    //api: 'http://hdimigdesweb/esb-ws/inspeccion/AutoInspeccion.svc'
    //api: 'http://192.168.66.169/esb-ws/inspeccion/AutoInspeccion.svc'
    fondo: '#EFEFEF',
    titulo: '#000000',
    leyenda: '#52658F',
    boton: '#006729',
    entrada: '#E8E8E8',
    tabla_opacity: 0.8,
    tabla_backgroundColor: "#00113a",
    tabla_color: "#ffffff",
    tabla_textAlign: "center",
    palabraClave: "TocoSeLlamaMiDoco",
};

export default settings;
