
/*eslint-disable no-eval */
/*eslint-disable no-useless-escape */
/*eslint-disable no-extra-bind */
/*eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Business from '@material-ui/icons/Business';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';
//import Alert from '@material-ui/lab/Alert';
import { perfilSFRest, empresaSFRest, usuarioPKRest, usuarioInsertaRest, usuarioModificaRest }  from '../Components/Home/Rests';
import properties from '../Components/Home/properties.js';


var optEmpresa = [];
var optPerfil = [];

//const { Step } = Steps;

const UsuarioVista = (props) => {

  const [empresa, setEmpresa] = useState({ value:'' , label: '' });
  const [perfil, setPerfil] = useState({value:'', label: ''});
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({ codpersoneria:'' });

  const [estBuscar, setEstBuscar] = useState({enEspera: false});
  const [estadoboton, setEstadoBoton] = useState({
    habilitaGrabar: false,
    habilitaSiguiente: false,
    habilitaCampos: false,
    habilitaNatural: false,
  });
  const [estadousuario, setEstadousuario] = useState({mensajeVisible: false, mensaje: '', color: ''})
  const [usuario, setUsuario] = useState('');
  const [clave, setClave] = useState('');
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [fechaacceso, setFechaacceso] = useState('');
  const [fecharegistro, setFecharegistro] = useState('');
  const [token, setToken] = useState('');
  //const [perfil, setPerfil] = useState('');
  const [codempresa, setCodempresa] = useState('');
  const [accion, setAccion] = useState('');
  // Empresa
  useEffect(() => {
    var token='123';
    var i=0;
    var url = '';
    url = properties.url + properties.empresasf;
    empresaSFRest(url,token,function(response) {
      optEmpresa = [];
      let data = JSON.stringify(response.data);
      console.log("Data empresas " + data);
      for(i=0;i<eval(data).length;i++) {
         let valor = JSON.stringify(response.data[i].codempresa).replace("\"","").replace("\"","");
         let etiqueta = JSON.stringify(response.data[i].nomempresa).replace("\"","").replace("\"","");
         optEmpresa.push({ value: valor, label: etiqueta });
      }
      console.log("('<<<<<<<<En LlenaPersoneria3 >>>>>>>>  " + optEmpresa);
      setEmpresa(optEmpresa);
    });
  }, []);

  // Perfil
  useEffect(() => {
    var token='123';
    var i=0;
    var url = '';
    optPerfil=[];
    url = properties.url + properties.perfilsf;
    perfilSFRest(url,token,function(response) {
      optPerfil = [];
      let data = JSON.stringify(response.data);
      console.log("Data perfiles " + data);
      for(i=0;i<eval(data).length;i++) {
         let valor = JSON.stringify(response.data[i].perfil).replace("\"","").replace("\"","");
         let etiqueta = JSON.stringify(response.data[i].perfil).replace("\"","").replace("\"","");
         optPerfil.push({ value: valor, label: etiqueta });
      }
      console.log("('<<<<<<<<En LlenaPersoneria3 >>>>>>>>  " + optEmpresa);
      setPerfil(optPerfil);
    });
  }, []);


  const handleInputusuarioChange = (e) => {
    const {name, value} = e.target;
    var letters = /^[A-Za-z]+$/;
    if(value.match(letters)) {
      setUsuario(e.target.value);
    }
    else {
      e.target.value=e.target.value.substring(0,e.target.value.length-1);
    }
  }

  const handleInputclaveChange = (e) => {
    const {name, value} = e.target;
    var lettersnumbersandsimbols = /^[A-Za-z0-9]+$/;
    if(value.match(lettersnumbersandsimbols)) {
      setClave(e.target.value);
    }
    else {
      e.target.value=e.target.value.substring(0,e.target.value.length-1);
      alert("Letras y núméros");
    }
  }

  const handleInputnombreChange = (e) => {
    const {name, value} = e.target;
    setNombre(e.target.value);
  }

  const handleInputcorreoChange = (e) => {
    const {name, value} = e.target;
    setCorreo(e.target.value);
  }

  const handleSelectEmpresaChange = (e) => {
    let vempresa = { value: e.value.replace("\"","").replace("\"",""), label: e.label.replace("\"","").replace("\"","")}
    setCodempresa(e.value.replace("\"","").replace("\"",""));
    setEmpresaSeleccionada(vempresa);
  }

  const handleSelectPerfilChange = (e) => {
    let vperfil = { value: e.value.replace("\"","").replace("\"",""), label: e.label.replace("\"","").replace("\"","")}
    setPerfil ({value: e.value.replace("\"","").replace("\"",""), label: e.value.replace("\"","").replace("\"","")});
    console.log("cliente.perfil = " + perfil.value);
    setPerfil(vperfil);
  }

  const buscar = () => {
    var token='123';
    var i=0;
    var url = '';
    var cusuario = usuario;
    console.log("En Buscar " + usuario.usuario);
    url = properties.url + properties.usuariopk;
    usuarioPKRest(url, token, cusuario, function(response) {
      console.log("Buscar encontrado  url = " + url + ' ; usuario=' + cusuario + ' ; response ' + JSON.stringify(response.result) );
      //console.log('FECAHAREGISTRO ' + JSON.stringify(response.data[0].fecharegistro).replaceAll("\"",""));
      if(response.result==="OK" && response.data.length > 0) {
        let vEstadoBoton = { habilitaGrabar: false, habilitaSiguiente: true, habilitaCampos: false }
        setEstadoBoton (vEstadoBoton);
        let vEstadousuario = { mensajeVisible: true, mensaje: 'Avance al siguiente paso', color: 'success' }
        setEstadousuario (vEstadousuario);
        //setUsuario(JSON.stringify(response.data[0].usuario).replaceAll("\"",""));
        setNombre(JSON.stringify(response.data[0].nombre).replaceAll("\"",""));
        setClave(JSON.stringify(response.data[0].clave).replaceAll("\"",""));
        setNombre(JSON.stringify(response.data[0].nombre).replaceAll("\"",""));
        setCorreo(JSON.stringify(response.data[0].correo).replaceAll("\"",""));
        setFechaacceso(JSON.stringify(response.data[0].fechaacceso).replaceAll("\"",""));
        setFecharegistro(JSON.stringify(response.data[0].fecharegistro).replaceAll("\"",""));
        setToken(JSON.stringify(response.data[0].token).replaceAll("\"",""));
        if(!fechaacceso || fechaacceso===null || fechaacceso==='null') {
          setFechaacceso('19000101');
        }
        if(!fecharegistro || fecharegistro===null || fecharegistro==='null') {
          setFecharegistro('19000101');
        }
        if(!token || token===null || token==='null') {
          setToken('123');
        }
        setPerfil({
          value: JSON.stringify(response.data[0].perfil).replaceAll("\"",""),
          label: JSON.stringify(response.data[0].perfil).replaceAll("\"","")
        });
        setCodempresa(JSON.stringify(response.data[0].codempresa).replaceAll("\"",""));
        console.log("USUARIO =>>>>> " + usuario.clave + ' perfil = ' + usuario.perfil + " Empresa " + usuario.codempresa);
        setAccion('Actualizar');
      }
      else {
        let vEstadoBoton = { habilitaGrabar: true, habilitaSiguiente: false, habilitaCampos: true }
        let vEstadousuario = { mensajeVisible: true, mensaje: 'Ingrese su información y grabe', color: 'success' }
        setEstadousuario (vEstadousuario);
        setClave('');
        setNombre('');
        setCorreo('');
        setFechaacceso('19000101');
        setFecharegistro('19000101');
        setToken('123');
        setPerfil({value: '', label: ''});
        setCodempresa('');
        setEstadoBoton (vEstadoBoton);
        setAccion('Agregar');
      }
    })
  };

  const grabar = () => {
    var vtoken = '123';
    setToken(vtoken);
    setFechaacceso('19000101');
    setFecharegistro('19000101');
    var url = '';
    /*
    const {
      usuario,
      clave,
      nombre,
      correo,
      fechaacceso,
      fecharegistro,
      token,
      perfil,
      codempresa
    } = usuario;
    */
    console.log('GRABANDO: ' + usuario.usuario + ' clave ' + usuario.clave + ' nombre: ' + usuario.nombre + 'fechaacceso: ' + usuario.fechaacceso + ' registro=' + usuario.fecharegistro);

    if(usuario==='') {
      alert("Debe ingresar usuario");
      return;
    }
    if(clave==='') {
      alert('Debe ingresar clave');
      return;
    } else {
      if(clave.length<6) {
        alert('Clave debe ser mayor a 6');
        return;
      }
    }
    if(nombre==='') {
      alert('Debe ingresar nombre');
      return;
    } else {
      if(nombre.length<8) {
        alert('Debe ingresar nombre y apellido');
        return;
      }
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test( correo )) {
      alert("Correo invalido");
      return;
    }
    if(perfil.value==='') {
      alert("Debe seleccionar Perfil");
      return;
    }
    if(codempresa==='') {
      alert("Debe seleccionar Empresa");
      return;
    }
    if (accion==='Agregar') {
      url = properties.url + properties.usuarioinserta;
      usuarioInsertaRest(
        url,
        vtoken,
        usuario,
        clave,
        nombre,
        correo,
        fechaacceso,
        fecharegistro,
        token,
        perfil.value,
        codempresa,
        function(response) {
         console.log("Respuesta Stringuify " + JSON.stringify(response));
         if((JSON.stringify(response.result).replaceAll('"',''))!=="OK") {
           //let vEstadousuario = { mensajeVisible: true, mensaje: 'Error al grabar al usuario, revise sus datos por favor', color: 'danger' }
           //setEstadousuario(vEstadousuario);
           alert('Error al grabar el usuario');
         } else {
           alert('Usuario regstrado exitosamente');
         }
      });
    } else if(accion==='Actualizar') {
      url = properties.url + properties.usuariomodifica;
      usuarioModificaRest(
        url,
        vtoken,
        usuario,
        clave,
        nombre,
        correo,
        fechaacceso,
        fecharegistro,
        token,
        perfil.value,
        codempresa,
        function(response) {
         console.log("Respuesta Stringuify " + JSON.stringify(response));
         if((JSON.stringify(response.result).replaceAll('"',''))!=="OK") {
           //let vEstadousuario = { mensajeVisible: true, mensaje: 'Error al grabar al usuario, revise sus datos por favor', color: 'danger' }
           //setEstadousuario(vEstadousuario);
           alert('Error al modificar el usuario');
         } else {
           alert('Usuario modificado exitosamente');
         }
      });
    } else {
      alert('Debe buscar el usuario');
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{marginTop: 150}}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Face />
        </Grid>
        <Grid item xs={4} justify="flex-start" >
          <TextField
            id="usuario.usuario"
            name="usuario.usuario"
            label="usuario"
            defaultValue=""
            helperText=""
            fullWidth
            required
            style = {{
              marginTop: -2,
            }}
            inputProps={{
              maxLength: 20,
              pattern: "[0-9]",

            }}
            onChange={handleInputusuarioChange}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={1} container justify = "center">
          <Button
            style = {{
             background: 'linear-gradient(45deg, #25B48B 30%, #258E53 90%)',
             color: '#FEF9E7',
             height: '100%',
             justifyContent: 'center',
             textTransform: 'capitalize',
            }}
            onClick={buscar}>
            Buscar
          </Button>
        </Grid>
        <Grid item xs={2}/>
        <Grid item xs={12}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Fingerprint />
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <TextField
            label="clave"
            value={clave}
            required
            helperText=""
            type="password"
            fullWidth
            style = {{
              marginTop: -2,
            }}
            inputProps={{
              maxLength: 20,
              pattern: "[a-z A-Z09]",
            }}
            onChange={handleInputclaveChange}
          />
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={12}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Person />
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <TextField
            label="nombre"
            value={nombre}
            required
            helperText=""
            fullWidth
            style = {{
              marginTop: -2,
            }}
            inputProps={{
              maxLength: 50,
              pattern: "[a-z A-Z]",
            }}
            //onChange={handleInputnombreChange}
            onChange={e => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={12}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Email />
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <TextField
            label="correo"
            value={correo}
            type="email"
            required
            defaultValue=""
            helperText=""
            fullWidth
            style = {{
              marginTop: -2,
            }}
            inputProps={{
              maxLength: 100,
              pattern: "[a-z A-Z@.]",
            }}
            onChange={e => setCorreo(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={12}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <People />
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <Select
            name="perfil" id="perfil"
            onChange={handleSelectPerfilChange}
            value={optPerfil.find(op => {return op.value === perfil.value})}
            options={optPerfil}

          />
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={12}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Business />
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <Select
            onChange={handleSelectEmpresaChange}
            value={optEmpresa.find(op => {return op.value === codempresa})}
            options={optEmpresa}

          />
        </Grid>
        <Grid item xs={4}/>

        <Grid item xs={12} />
        <Grid item xs={4}/>
        <Grid item xs={4} container justify = "center">
          <Button
            style = {{
             background: 'linear-gradient(45deg, #25B48B 30%, #258E53 90%)',
             color: '#FEF9E7',
             height: '100%',
             justifyContent: 'center',
             textTransform: 'capitalize',
            }}
            onClick={grabar}>
            {accion}
          </Button>
        </Grid>
        <Grid item xs={4}/>
      </Grid>
    </div>
  );

}

export default UsuarioVista;
