export const ACTUALIZA_LOGIN_ACTION = 'ACTUALIZA_LOGIN_ACTION';
export const ACTUALIZA_DATO_LOGIN_ACTION = 'ACTUALIZA_DATO_LOGIN_ACTION';
export const OBTIENE_LOGIN_ACTION = 'OBTIENE_LOGIN_ACTION';

export const actualizaLogin = vLogin => {
  return {
    type: ACTUALIZA_LOGIN_ACTION,
    payload: {
      login: vLogin,
    },
  };
};

export const actualizaDatoLogin = (vAtributo, vDato, vLogin) => {
  return {
    type: ACTUALIZA_DATO_LOGIN_ACTION,
    payload: {
      atributo: vAtributo,
      dato: vDato,
      login: vLogin,
    },
  };
};

export const obtieneLogin = () => {
  return {
    type: OBTIENE_LOGIN_ACTION,
  };
};
