import React, { Component } from 'react';
import {connect} from 'react-redux';
import {obtieneLogin} from '../../../Actions/index';
import Select from 'react-select';
import { properties } from './../properties';
import { rest_get } from './../Rests';


class RamoMulti extends Component {

  constructor(props){
    super(props);
    this.state = {
      codempresa: this.props.login.codempresa,
      perfil: this.props.login.perfil,
      opRamo: [],
      cuadro: this.props.cuadro,
      dato: this.props.dato,
    }
  }

  getRamo() {
    var ctoken='pepito';
    var url = properties.url + properties.ramoenpermisoramocombo;
    var parametros = '/' + this.state.codempresa  + '/' + this.state.perfil;
    rest_get(url, ctoken, parametros, function(resData) {
      this.setState({ opRamo: resData});
    }.bind(this))
  }

  componentDidMount(){
    this.getRamo();
  }

  cambio = selectedOptions => {
     let valores = { cuadro: this.state.cuadro, dato: this.state.dato, valor: selectedOptions }
    this.setState({ valores });
    this.props.callbackFromParent(valores);
    //this.state.selectedOptions.map(o => { alert(o.value) });
  };

  render() {
    const { selectedOption } = this.state;
    return(
      <Select options={ this.state.opRamo } autosize={true} value={selectedOption} onChange={this.cambio} isMulti= {true}  />
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.loginObj,
  };
}

export default connect(
   mapStateToProps, {
     obtieneLogin,
})( RamoMulti);
