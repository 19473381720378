import React, { useState, useEffect } from 'react';

import {connect} from 'react-redux';
import {actualizaDatoLogin, actualizaLogin} from '../../../src/Actions/index.js';

import PropTypes from 'prop-types';
import estilo from '../../Style/style.css';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Face from '@material-ui/icons/Face';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Line } from 'react-chartjs-2';
import "react-tabs/style/react-tabs.css";
import { loginRest, validaUsuarioRest } from './Rests';
import { properties } from './properties';

const styles = theme => ({
  content: {
  	...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  }
});

function Login(props) {
  const [login, setlogin] = useState({
    usuario: '',
    clave: '',
  });


  const handleInputusuarioChange = (e) => {
    const {name, value} = e.target;
    var letters = /^[A-Za-z]+$/;
    if(value.match(letters)) {
      login.usuario= e.target.value;
    }
    else {
      e.target.value=e.target.value.substring(0,e.target.value.length-1);
    }
  }

  const handleInputclaveChange = (e) => {
    const {name, value} = e.target;
    var lettersnumbersandsimbols = /^[A-Za-z0-9.-_!"·$%&/()=?¿ª^*¨Ç"]+$/;
    if(value.match(lettersnumbersandsimbols)) {
      login.clave= e.target.value;
    }
    else {
      e.target.value=e.target.value.substring(0,e.target.value.length-1);
      alert("Letras, núméros y símbolos");
    }
  }


  const ingresar = () => {
    var url = properties.url + properties.login;
    var vrespuesta = '';
    var vtoken = '';
    var vperfil = '';
    var vcodempresa = '';
    var vgraficar = '';
    var vgrabar = '';
    var vver = '';

    var objLogin = null;
    loginRest(url, login.usuario, login.clave, function(response) {
      vrespuesta = response[0].respuesta.replace('"','').replace('"','');
      console.log(vrespuesta);
      if(vrespuesta==='OK') {
        vtoken = response[0].token.replace('"','').replace('"','');
        vperfil = response[0].perfil.replace('"','').replace('"','');
        vcodempresa = response[0].codempresa.replace('"','').replace('"','');
        vgraficar = response[0].graficar;
        vgrabar = response[0].grabar;
        vver = response[0].ver;
        //vcodgrupo = response[0].tiempo.replace('"','').replace('"','');
        objLogin = props.login;
        objLogin.usuario = login.usuario;
        objLogin.clave = login.clave;
        objLogin.token = vtoken;
        objLogin.perfil = vperfil;
        objLogin.codempresa = vcodempresa;
        objLogin.graficar = vgraficar;
        objLogin.grabar = vgrabar;
        objLogin.ver = vver;
        //objLogin.codgrupo = vcodgrupo;
        props.actualizaLogin(objLogin);
        var { history } = props;
        console.log('A Home con perfil ' + vperfil);
        if(vperfil!=='ADMINCIA' && vperfil!=='ADMIN') {
          history.push('/home', {
            usuario: login.usuario,
            clave: login.clave,
            token: vtoken,
            perfil: vperfil,
            codempresa: vcodempresa,
          });
        } else {
          history.push('/menu', {
            usuario: login.usuario,
            clave: login.clave,
            token: vtoken,
            perfil: vperfil,
            codempresa: vcodempresa,
          });
        }
      } else {
      alert('Usuario no válido');
    }
  });
}
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{marginTop: 150}}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Face />
        </Grid>
        <Grid item xs={4} justify="flex-start" >
          <TextField
            id="login.usuario"
            name="login.usuario"
            label="usuario"
            defaultValue=""
            helperText=""
            fullWidth
            required
            style = {{
              marginTop: -2,
            }}
            inputProps={{
              maxLength: 20,
              pattern: "[0-9]",

            }}
            onChange={handleInputusuarioChange}
          />
        </Grid>
        <Grid item xs={4}/>
        <Grid item xs={12}/>
        <Grid item xs={3}/>
        <Grid item xs={1} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Fingerprint />
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <TextField
            id="usuario.clave"
            name="usuario.clave"
            label="clave"
            required
            //value={modalidad.nommodalidad}
            defaultValue=""
            helperText=""
            type="password"
            fullWidth
            style = {{
              marginTop: -2,
            }}
            inputProps={{
              maxLength: 20,
              pattern: "[a-z A-Z]",
            }}
            onChange={handleInputclaveChange}
          />
        </Grid>
        <Grid item xs={4}/>
        <Grid item xs={12} />
        <Grid item xs={4}/>
        <Grid item xs={4} container justify = "center">
          <Button
            style = {{
             background: 'linear-gradient(45deg, #25B48B 30%, #258E53 90%)',
             color: '#FEF9E7',
             height: '100%',
             justifyContent: 'center',
             textTransform: 'capitalize',
            }}
            onClick={ingresar}>
            Ingresar
          </Button>
        </Grid>
        <Grid item xs={4}/>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    login: state.loginObj,
  };
}

export default connect(
   mapStateToProps, {
     actualizaDatoLogin,
     actualizaLogin,
})(Login);
