import React, { useState, useEffect, forwardRef } from 'react';
import estilo from '../Style/style.css';
import properties from '../Components/Home/properties.js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import {
  empresaInsertaRest,
  empresaInsertaLoteRest,
  empresaEliminaLoteRest,
  empresaSFRest,
} from '../Components/Home/Rests';

/* MATERIAL TABLE IMPORTS INICIO */
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
/* MATERIAL TABLE IMPORTS FIN */
var objemp = {};
var objsino = {};
var optEmpresa = [];
var vrows = [];

/*
const columns = [
  { field: 'id', headerName: 'ID', width: 120 },
  { field: 'modalidad', headerName: 'Modalidad', width: 220 },
  { field: 'ramo', headerName: 'Ramo', width: 220 },
  { field: 'codmodalidad', headerName: 'cm', type: 'number', width: 30 },
  { field: 'codramo', headerName: 'cr', sortable: false, hide: true, width: 30 },
];
*/


const EmpresaVista = (props) => {
  const [empresa, setempresa] = useState({
    codempresa: '',
    nomempresa: '',
  });
  const [codempresaError, setCodEmpresaError] = useState({
    error: false,
    label: "",
    helperText: "",
    validateInput: false
});
  const [columns, setColumns] = useState([
    {
      title: 'Código',
      field: 'codempresa',
      editComponent: props => (
        <TextField
          type="text"
          error={
            !props.value && codempresaError.validateInput
              ? codempresaError.error
              : false
          }
          helperText={
            !props.value && codempresaError.validateInput
              ? codempresaError.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={e => {
            if (codempresaError.validateInput) {
              setCodEmpresaError({
                ...codempresaError,
                validateInput: false
              });
            }
            props.onChange(e.target.value);
          }}
        />
      )
    },
    { title: 'Nombre', field: 'nomempresa' },
  ]);

  const [state, setState] = React.useState({
    data: [],
  });
  const [habilita,setHabilita] = React.useState({validacion: false})

  useEffect(() => {
    var token = '123';
    empresaSF(token);
  }, []);

  const [datos, setDatos] = useState([]);
  const  empresaSF = (token) => {
    var i = 0;
    optEmpresa = [];
    var url = properties.url + properties.empresasf;
    empresaSFRest(url, token, function(response) {
      let data = JSON.stringify(response.data);
      console.log('DDDAAAATTTAAA: ' + data);
      setState({data: response.data});
/*
      setColumns([
        { title: 'Código', field: 'codempresa' },
        { title: 'Nombre', field: 'nomempresa' },
      ]);
*/
/*
      for(i=0;i<eval(data).length;i++) {
        let valor = JSON.stringify(response.Data[i].codmodalidad).replace("\"","").replace("\"","");
        let etiqueta = JSON.stringify(response.Data[i].nommodalidad).replace("\"","").replace("\"","");
        optmodalidad.push({ value: valor, label: etiqueta });
        optmod.push({id: valor, name: etiqueta});
      }
      objmod = optmod.reduce(function(acc, cur, i) {
        acc[cur.id] = cur.name;
        return acc;
      }, {});
      setColumns([
        { title: 'Ramo', field: 'ramo', lookup: objram },
      ]);
*/
    }.bind(this));
  }

  const hizoClick = (event, rowData) => {
    alert('hizo click en la fila  rowData=' + JSON.stringify(rowData));
    //const index = rslts.indexOf(rowData);

    /*
    let rslts = this.state.results;
    rslts[index].tableData.checked = !rslts[index].tableData.checked;
    this.setState({ results: rslts }, () => {
      this.handleSelectChange(event, rowData);
    });
    */
  };
  const handleInputcodempresaChange = (e) => {
    const {name, value} = e.target;
    if(value.match("^[0-9]*$") === null) {
      e.target.value=e.target.value.substring(0,e.target.value.length-1);
      alert("Numérico");
    }
    else {
      empresa.codempresa= e.target.value;
    }
  }

  const handleInputnomempresaChange = (e) => {
    const {name, value} = e.target;
    if(value.match("^[a-z A-Z]*$") === null) {
      e.target.value=e.target.value.substring(0,e.target.value.length-1);
      alert("Letras");
    }
    else {
      empresa.nomempresa= e.target.value;
    }
  }


  const grabar = () => {
    //alert('data=' + JSON.stringify(state.data) + ' LEN=' + eval(state.data).length);
    var i = 0;
    var token='123';
    var vcodempresa = '';
    var vnomempresa = '';
    var n = eval(state.data).length;
    var ng = 0;
    var parametro = {};
    var respuesta = '';
    var vdata = null;
    var url = properties.url + properties.empresainserta;
    for (i=0;i<n;i++) {
      vdata = JSON.stringify(eval(state.data[i]));
      //console.log('vdata = '+ vdata);
      //console.log(' ne= ' + JSON.stringify(state.data[i].nomempresa));
      //console.log(' ce= ' + JSON.stringify(state.data[i].codempresa));
      vcodempresa = JSON.stringify(state.data[i].codempresa).replaceAll('"','');
      vnomempresa = JSON.stringify(state.data[i].nomempresa).replaceAll('"','');
      empresaInsertaRest(url, token, vcodempresa, vnomempresa, function(response) {
        //console.log("RESPONSE (" + ng + ") = " + JSON.stringify(response));
        respuesta = JSON.stringify(response.result).replaceAll('"','');
        if (respuesta==='OK') {
          ng++;
        }
      });
    }
    if(ng===n) {
      alert('Datos almacenados');
    } else {
      alert('Intente grabar nuevamente');
    }
  }

  const grabarLote = () => {
    //alert('data=' + JSON.stringify(state.data) + ' LEN=' + eval(state.data).length);
    var i = 0;
    var token='123';
    var vcodempresa = '';
    var vnomempresa = '';
    var n = eval(state.data).length;
    var ng = 0;
    var parametro = {};
    var respuesta = '';
    var respuesta2 = '';
    var vdata = null;
    var url = '';
    var vcodempresa = '-1';
    url = properties.url + properties.empresaeliminasf;
    empresaEliminaLoteRest(url, token, function(response) {
      respuesta = JSON.stringify(response.result).replaceAll('"','');
      console.log("RESULTADO ELIMINACION POR LOTE: " + respuesta + " result = " +  JSON.stringify(response.result));
      if (respuesta==='OK') {
        url = properties.url + properties.empresainsertalote;
        var objJsonproducto_sringify = JSON.stringify(state.data);
        empresaInsertaLoteRest(url, token, objJsonproducto_sringify, function(response) {
          respuesta2 = JSON.stringify(response.result).replaceAll('"','');
          console.log("RESULTADO INSERCION POR LOTE: " + respuesta2 + " result = " +  JSON.stringify(response.result));
          if (respuesta2==='OK') {
            alert('Almacenado');
          } else {
            alert('Intente nuevamente por favor');
          }
        });
      }
    });
  }

  const volver = () => {
    var { history } = props;
    console.log('Retornando');
    history.push('/empresa');
  }
/*
  const agregar = () => {
    var vid = vrows.length + 1;
    vid = vcodmodalidad + '|' + vcodramo;
    //alert('vid ' + vcodmodalidad + '|' + vcodramo + 'vnommod ' + vnommodalidad + ' nomramo ' + vnomramo + ' vrows.length = ' + vrows.length + ' rows.length ' + rows.length);
    vrows.push({
      id: vid,
      modalidad: vnommodalidad,
      ramo: vnomramo,
      codmodalidad: vcodmodalidad,
      codramo: vcodramo,
    })

    setDatos(vrows);
    console.log('datos: ' + JSON.stringify(datos));


    vrows.push({
      id: vid,
      modalidad: vnommodalidad,
      ramo: vnomramo,
      codmodalidad: vcodmodalidad,
      codramo: vcodramo,
    });
    setRows(vrows);

  }
  */
  const eliminar = () => {
    var { history } = props;
  }

  return(
    <div className={estilo.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={estilo.gridcentro}>
          <Typography variant='h5' color='primary' display='initial' align='center' noWrap>
              Empresa
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            icons={tableIcons}
            title="Empresas"
            columns={columns}
            data={state.data}
            onRowClick={hizoClick}
            editable={{
              onRowAdd: newData => new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  setState(prevState => {
                    const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                  });
                }, 600);
              }),
              onRowUpdate: (newData, oldData) => new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    setState(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }, 600);
              }),
              onRowDelete: oldData => new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  setState(prevState => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }, 600);
              }),
            }}
          />
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={7} style={estilo.gridcentro}>
          <Button
            style = {{
             height: '100%',
            }}
            size="small"
            variant="outlined"
            color="primary"
            onClick={grabarLote}>
            Grabar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default EmpresaVista;
