import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import EmpresaVista from './EmpresaVista';
import PerfilVista from './PerfilVista';
import UsuarioVista from './UsuarioVista';
import PermisosVista from './PermisosVista';
import { permiso } from '../Components/Home/Funciones';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import Business from '@material-ui/icons/Business';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});


const MenuRoute = (props) => {
  const { classes } = props;
  const [visible, setVisible] = useState({
    empresa: false,
    perfil: false,
    permisocompania: false,
    permisoramo: false,
    usuario: false,
    usuarioperfil: false,
  });
  useEffect(() => {
    console.log( ' En usefect Menu perfil = ' + props.location.state.perfil + ' rut=' + props.location.state.rut);
    setVisible(permiso(props.location.state.perfil));
  }, [props.location.state]);

  const irAEmpresa = () => {
    var { history } = props;
     history.push('/empresa');
  }
  const irAPerfil = () => {
    var { history } = props;
     history.push('/perfil');
  }
  const irAUsuario = () => {
    var { history } = props;
     history.push('/usuario');
  }
  return (
    <Router>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{marginTop: 70}}/>
        <Grid item xs={5}/>
        <Grid item xs={2} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Button variant="contained" style={{ backgroundColor: '#FF7F50', width: 200, height: 100 }} onClick={irAEmpresa}>
            <Business />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Empresas
          </Button>
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={12}/>
        <Grid item xs={5}/>
        <Grid item xs={2} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Button variant="contained" style={{ backgroundColor: '#FFFF00', width: 200, height: 100 }} onClick={irAPerfil}>
            <People />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Perfil
          </Button>
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={12}/>
        <Grid item xs={5}/>
        <Grid item xs={2} justify="flex-end" style={{marginTop: 20, textAlign: 'right'}}>
          <Button variant="contained" style={{ backgroundColor: '#90EE90', width: 200, height: 100 }} onClick={irAUsuario}>
            <Person />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Usuario
          </Button>
        </Grid>
        <Grid item xs={5}/>
        <Switch>
          <Route path='/empresa' component={EmpresaVista} />
          <Route path='/perfil' component={PerfilVista} />
          <Route path='/usuario' component={UsuarioVista} />
        </Switch>
      </Grid>
    </Router>
  );
}

export default MenuRoute;
