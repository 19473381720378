import {
  ACTUALIZA_DATO_LOGIN_ACTION,
  ACTUALIZA_LOGIN_ACTION,
  OBTIENE_LOGIN_ACTION,
} from '../Actions/login.Actions';

const estadoInicialLogin = {
  usuario: '',
  clave: '',
  token: '',
  perfil: '',
  codempresa: '',
  graficar: '',
  grabar: '',
  ver: '',
};

const loginObj = (state = estadoInicialLogin, action) => {
  switch (action.type) {
    case ACTUALIZA_LOGIN_ACTION:
      return action.payload.login;
    case ACTUALIZA_DATO_LOGIN_ACTION:
      return actualizaDatoLogin(
        JSON.stringify(action.payload.atributo)
          .replace('"', '')
          .replace('"', ''),
        JSON.stringify(action.payload.dato)
          .replace('"', '')
          .replace('"', ''),
        action.payload.login,
      );
    case OBTIENE_LOGIN_ACTION:
      return state;
    default:
      return state;
  }
};

const actualizaDatoLogin = (atributo, dato, vLogin) => {
  var vsesionActiva = JSON.stringify(vLogin.sesionActiva)
    .replace('"', '')
    .replace('"', '');
  var vusuario = JSON.stringify(vLogin.usuario)
    .replace('"', '')
    .replace('"', '');
  var vclave = JSON.stringify(vLogin.clave)
    .replace('"', '')
    .replace('"', '');
  var vtoken = JSON.stringify(vLogin.token)
    .replace('"', '')
    .replace('"', '');
  var vperfil = JSON.stringify(vLogin.perfil)
    .replace('"', '')
    .replace('"', '');
  var vcodempresa = JSON.stringify(vLogin.codempresa)
    .replace('"', '')
    .replace('"', '');
  var vgraficar = JSON.stringify(vLogin.graficar)
    .replace('"', '')
    .replace('"', '');
  var vgrabar = JSON.stringify(vLogin.grabar)
    .replace('"', '')
    .replace('"', '');
  var vver = JSON.stringify(vLogin.ver)
    .replace('"', '')
    .replace('"', '');
  if (atributo === 'usuario') {
    vusuario = dato;
  } else if (atributo === 'clave') {
    vclave = dato;
  } else if (atributo === 'token') {
    vtoken = dato;
  } else if (atributo === 'perfil') {
    vperfil = dato;
  } else if (atributo === 'codempresa') {
    vcodempresa = dato;
  } else if (atributo === 'graficar') {
    vgraficar = dato;
  } else if (atributo === 'grabar') {
    vgrabar = dato;
  } else if (atributo === 'ver') {
    vver = dato;
  }
  let objetoLogin = {
    usuario: vusuario,
    clave: vclave,
    token: vtoken,
    perfil: vperfil,
    codempresa: vcodempresa,
    graficar: vgraficar,
    grabar: vgrabar,
    ver: vver,
  };
  return objetoLogin;
};

export default loginObj;
